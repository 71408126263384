import React from 'react';
import BookingHistoryContainer from "../home/container/BookingHistoryContainer";

export default class History extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loadingData: true
        }
    }
    
    render() {
        return (
            <React.Fragment>
                <div className="container">
                    <BookingHistoryContainer historyPage={true} history={this.props.history} />
                </div>
            </React.Fragment>
        );
    }
}
