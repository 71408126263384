import React from 'react';

export default class LoadingTicket extends React.Component {
    render(){
        return(
            <React.Fragment>
                <div className="ticket-detail mt-3 border rounded p-4 mb-4 bp3-skeleton">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 mt-4">
                            <p>Journey Station</p>
                            <p></p>
                            
                            <div className="mt-4">
                                <h6>Pick up</h6>
                            </div>
                            
                            <div className="mt-4">
                                <h6>Bus Name</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}