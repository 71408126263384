import React from 'react';
import {Redirect} from 'react-router-dom';
import {Auth} from "gg-react-utilities";
import TopBar from "../core/components/TopBar";
import TripDetail from "./components/TripDetail";
import PassengerFormContainer from "./containers/PassengerFormContainer";
import coinimg from "./../core/images/coin.png";

import Config from '../Config';
import {ssoInstance} from 'gg-react-utilities';

export default class Passenger extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            tripDetail:null,
            busDetail:null,
            selectedSeats:null,
            selectedBoardingPoint:null,
            selectedDroppingPoint:null,
            coinBalance:0
        }
    }
    
    async componentDidMount() {
        
        if(!await Auth.isLoggedIn()){
            Auth.login(true)
        } else {
            this.GetGGCoinValue()
        }
        
        this.setState({
            tripDetail:this.props.location.state ? this.props.location.state.query.tripDetail : null,
            busDetail:this.props.location.state ? this.props.location.state.query.busDetail : null,
            selectedSeats:this.props.location.state ? this.props.location.state.query.selectedSeat : null,
            selectedBoardingPoint:this.props.location.state ? this.props.location.state.query.selectedBoardingPoint : null,
            selectedDroppingPoint:this.props.location.state ? this.props.location.state.query.selectedDroppingPoint : null
        })
    }

    GetGGCoinValue() {
        ssoInstance.get(Config.API_URL + 'check-coin-balance')
            .then(res => {
                this.setState({coinBalance: res.data.data.coin_balance});
            });
    }

    render() {
        return (
            
            (this.props.location.state === undefined || this.props.location.state.query.selectedSeat === undefined)?
            (
                <Redirect to="/bus/home" />
            )
            :
            (
                <React.Fragment>
                    <div className="container passenger">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="row">
                                {
                                    this.state.tripDetail?
                                    <TopBar history={this.props.history} title={this.state.tripDetail.source_name +' to '+ this.state.tripDetail.destination_name + ''}/>
                                    :
                                    <TopBar history={this.props.history} title={'searching...'}/>
                                }
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 px-s-4">
                                <div className="coin-show-block pull-right">GG Coins: <img style={{"vertical-align": "middle"}} src={coinimg} alt="" height="22" width="22" /> {this.state.coinBalance}</div>
                            </div>
                        </div>


                        <TripDetail tripDetail={this.state.tripDetail} />
						<div className="list-container py-3 mb-5">
							<PassengerFormContainer 
								tripDetail={this.state.tripDetail} 
								busDetail={this.state.busDetail} 
								selectedSeats={this.state.selectedSeats} 
								selectedBoardingPoint={this.state.selectedBoardingPoint} 
								selectedDroppingPoint={this.state.selectedDroppingPoint} 
							/>
						</div>
                    </div>
                </React.Fragment>
            )
        );
    }
}