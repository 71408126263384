import { connect } from 'react-redux';
import SelectedSeats from '../components/SelectedSeats';
import * as actions from '../../core/actions';

const mapStateToProps = state => {

    return{
        selectedSeats: state.Booking.selectedSeats
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {

    return {
        resetSelectSeats:()=>{
            dispatch(actions.resetSelectedSeats());
        }
    }
}

const SelectedSeatsContainer = connect(mapStateToProps, mapDispatchToProps)(SelectedSeats);

export default SelectedSeatsContainer;