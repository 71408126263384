import React from 'react';
import {Link} from "react-router-dom";
import moment from 'moment';
import Config from '../../Config';
import { Auth } from 'gg-react-utilities';
import {Button, Intent} from "@blueprintjs/core";
import LoadingBookingHistory from "./LoadingBookingHistory";
import ShowBookingDetail from "./ShowBookingDetail";
import TopBar from "../../core/components/TopBar";
import {numberFormat} from './../../CommonFunction';

export default class BookingHistory extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            loadingData: true,
            loadMoreUrl:'',
            olderBookings: []
        }

        this.loadMoreData = this.loadMoreData.bind(this);
    }

    async componentDidMount() {
        if (await Auth.isLoggedIn()){
            let param = {
                per_page:this.props.historyPage ? '10' : '5',
                url:Config.API_URL + 'booking_history'
            }
            this.props.getBookingHistory(param);
            setTimeout(() => {
                this.setState({
                    loadingData: false,
                    olderBookings: this.props.bookingHistoryList.data
                });
            }, 1000);
        } else {
            setTimeout(() => {
                this.setState({
                    loadingData: false
                });
            }, 1000);
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            olderBookings:this.state.olderBookings.concat(nextProps.bookingHistoryList.data),
            loadMoreUrl:nextProps.bookingHistoryList.next_page_url,
            loadingData:false
        })
    }

    downloadTicket = (bookingId) => {
        window.location.href = Config.API_URL + 'ticket-download/'+bookingId;
    }

    renderBookingStatus(status, statusType){
        switch (status){
            case 0:
                return <span className="text-info">{statusType}</span>;
            case 1:
                return <span className="text-warning">{statusType}</span>;
            case 2:
                return <span className="text-success">{statusType}</span>;
            case 3:
                return <span className="text-danger">{statusType}</span>;
            case 4:
                return <span className="text-danger">{statusType}</span>;
            case 5:
                return <span className="text-success">{statusType}</span>;
            default:
                return "";
        }
    }

    renderBookingStatusBadge(status, statusType){
        switch (status){
            case 0:
                return <span className="badge badge-warning font-14">{statusType}</span>;
            case 1:
                return <span className="badge badge-warning font-14">{statusType}</span>;
            case 2:
                return <span className="badge badge-success font-14">{statusType}</span>;
            case 3:
                return <span className="badge badge-danger font-14">{statusType}</span>;
            case 4:
                return <span className="badge badge-danger font-14">{statusType}</span>;
            case 5:
                return <span className="badge badge-success font-14">{statusType}</span>;
            default:
                return "";
        }
    }

    loadMoreData() {
        let param = {
            per_page:this.props.historyPage ? '10' : '5',
            url:this.state.loadMoreUrl,
        }
        this.props.getBookingHistory(param);
    }

    render(){
        return(
            <React.Fragment>
                <div className="rounded-0 mt-4 recharge-older text-left mb-5 mt-s-0 pt-s-0">
                    {
                        this.props.historyPage ?
                            <TopBar history={this.props.history} title="Booking History" />
                        :
                            <h2 className="bp3-text-xlarge heading mb-5 pt-2 mb-s-4">Booking History</h2>
                    }
                    
                    {
                        this.state.loadingData ?    
                            <div className="row">
                                <LoadingBookingHistory />
                            </div>
                        :
                            this.state.olderBookings && this.state.olderBookings.length > 0 ?
                                <React.Fragment>
                                    <div className="booking-history">                
                                        <div>
                                            <table className="bp3-html-table">
                                                <tbody>
                                                    {
                                                        this.state.olderBookings.map((trip, key) => {

                                                            return (
                                                                <React.Fragment key={key}>
                                                                    <tr>
                                                                        <td className="text-center"><div className="operator-icon"><i className="material-icons">directions_bus</i></div></td>
                                                                        <td><div className="operator-name f-16">{trip.source_name+ ' To '+trip.destination_name}</div></td>
                                                                        <td><div className="mobile-number f-16">{trip.booking_id}</div></td>
                                                                        <td><div className="mobile-number f-16">{trip.doj ? moment(trip.doj).format('Do MMMM YYYY') : '-'}</div></td>
                                                                        <td className="fw-500">{trip.total > 0 ? numberFormat(trip.total) : 0}</td>
                                                                        <td className="fw-500"><div className="mobile-number f-16">{this.renderBookingStatus(trip.status, trip.status_type)}</div></td>
                                                                        <td className="btn-ticket">
                                                                            <ShowBookingDetail title="Booking Details" className="bp3-dialog-large">
                                                                                <div className="row">
                                                                                    <div className="col-md-4 col-sm-4 col-6 mb-3 pr-s-1">
																						<div className="">
																							<p className="mb-0">Booking ID</p>
																							<p>{trip.booking_id}</p>
																						</div>
                                                                                    </div>
                                                                                
                                                                                    <div className="col-md-4 col-sm-4 col-6 mb-3 pl-s-1">
																						<div className="">
																							<p className="mb-0">Ticket No</p>
																							<p>{trip.ticket_id ? trip.ticket_id : "-"}</p>
																						</div>
                                                                                    </div>
																					
																					<div className="col-md-4 col-sm-4 col-6 mb-3 pr-s-1">
																						<div className="">
																							<p className="mb-0">Booking Status</p>
																							<p>{this.renderBookingStatusBadge(trip.status, trip.status_type)}</p>
																						</div>
																					</div>
																					
																					<div className="col-md-4 col-sm-4 col-6 mb-3 pl-s-1">
																						<div className="">
																							<p className="mb-0">Journey Station</p>
																							<p>{trip.source_name + ' to '+ trip.destination_name}</p>
																						</div>
																					</div>
																					
																					<div className="col-md-4 col-sm-4 col-6 mb-3 pr-s-1">
																						<div className="">
																							<p className="mb-0">Journey Date Time</p>
																							<p>{trip.doj ? moment(trip.doj).format('Do MMMM YYYY, HH:mm') : '-'}</p>
																						</div>
																					</div>
																					
																					<div className="col-md-4 col-sm-4 col-6 mb-3 pl-s-1">
																						<div className="">
																							<p className="mb-0">Cashback Choice</p>
																							<p>{ (trip.cb_choice) ? trip.cb_choice : ''}</p>
																						</div>
																					</div>
                                                                                    {
                                                                                        (trip.convenience_charge > 0) ? 
                                                                                            <React.Fragment>
                                                                                                <div className="col-md-4 col-sm-4 col-6 mb-3 pr-s-1">
                                                                                                    <div className="">
                                                                                                        <p className="mb-0">Base Amount</p>
                                                                                                        <p>{ trip.base_fare ? numberFormat(trip.base_fare) : 0 }</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            
                                                                                                <div className="col-md-4 col-sm-4 col-6 mb-3 pl-s-1">
                                                                                                    <div className="">
                                                                                                        <p className="mb-0">Convenience Charge</p>
                                                                                                        <p>{ trip.convenience_charge ? numberFormat(trip.convenience_charge) : 0 }</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                        
                                                                                                <div className="col-md-4 col-sm-4 col-6 mb-3 pr-s-1">
                                                                                                    <div className="">
                                                                                                        <p className="mb-0">Payment Charge</p>
                                                                                                        <p>{ trip.payment_gateway_charge ? numberFormat(trip.payment_gateway_charge) : 0 }</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </React.Fragment>
                                                                                        :
                                                                                         ''
                                                                                    }

																					<div className="col-md-4 col-sm-4 col-6 mb-3 pr-s-1">
																						<div className="">
																							<p className="mb-0">Total Amount</p>
																							<p>{ trip.total ? numberFormat(trip.total) : 0 }</p>
																						</div>
																					</div>
                                                                                
                                                                                    <div className="col-md-4 col-sm-4 col-6 mb-3 pl-s-1">
																						<div className="">
																							<p className="mb-0">Coin Discount</p>
																							<p>{ trip.coin_discount ? numberFormat(trip.coin_discount) : 0 }</p>
																						</div>
                                                                                    </div>

                                                                                    <div className="col-md-4 col-sm-4 col-6 mb-3 pl-s-1">
																						<div className="">
																							<p className="mb-0">UPV Status</p>
																							<p><span className="badge badge-primary font-14">{ trip.is_sent_to_upv}</span></p>
																						</div>
                                                                                    </div>
                                                                                </div>
                                                                               
                                                                                {
                                                                                    trip.status === 2 ?
                                                                                    <div className="row">
                                                                                        <div className="col-md-4 col-sm-4 col-6 mb-3">
                                                                                            <div className="">
                                                                                                <Button intent={Intent.PRIMARY} onClick={()=>this.downloadTicket(trip.booking_id)} className="pull-left">Download Ticket</Button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    :
                                                                                    ''
                                                                                }
                                                                                
                                                                            </ShowBookingDetail>
                                                                        </td>
                                                                    </tr>
                                                                </React.Fragment>
                                                            )
                                                        })
                                                    }
                                                </tbody>
										    </table>
									    </div>
                                        {
                                            this.props.historyPage ? 
                                                this.state.loadMoreUrl ?
                                                    <span onClick={this.loadMoreData} className="text-center view-all">View More</span>
                                                :
                                                    ''
                                            :
                                                <Link to="/order-history" className="text-center view-all">View All</Link>
                                        }
								    </div>
                                </React.Fragment>
                            :
                                <div className="row">
                                    <div className="no-record">No booking history found</div>
                                </div>
                    }
                </div>
            </React.Fragment>
        );
    }
}