import React from 'react';
import BusSearchList from "./BusSearchList";
import BusSearchBar from "./BusSearchBar";
import _ from 'lodash';
import moment from 'moment';

export default class Trip extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tripList:[],
            isTripDataFiltered:false,
            sortOption:"availableSeats:desc",
            filterOptions:{
                operators:[],
                busTypes:[],
                boardingPoints:[],
                droppingPoints:[]
            }
        }
    }

    componentDidMount(){
        if(this.props.searchParam){
            this.setState({
                filterOptions:[],
                tripList:[]
            });

            this.props.getTripList(this.props.searchParam);
            this.setState({
                tripList: this.props.tripList
            });
        }
    }

    componentWillReceiveProps(nextProps){
        
        let operatorOptions = this.getOperatorOptions(nextProps);
        let busTypeOptions = this.getBusTypeOptions(nextProps);
        let boardingPointOptions = this.getBoardingPointsOptions(nextProps);
        let droppingPointOptions = this.getDroppingPointsOptions(nextProps);
        
        this.setState({
            loadingData: false,
            tripList: nextProps.tripList,
            filterOptions:{
                operators: operatorOptions,
                busTypes: busTypeOptions,
                boardingPoints:boardingPointOptions,
                droppingPoints:droppingPointOptions
            }
        });
    }

    getBusTypeOptions = (nextProps) => {

        let busTypes = new Set();
        nextProps.tripList.forEach((trip)=>{busTypes.add(trip.busType)});
        let busTypeOptions = [];

        busTypes.forEach((busType)=>{
            busTypeOptions.push({
                value:busType,
                label:busType
            });
        });
        return busTypeOptions;
    }

    getOperatorOptions = (nextProps) => {

        let operators = new Set();
        nextProps.tripList.forEach((trip)=>{operators.add(trip.travels)});
        let operatorOptions = [];

        operators.forEach((operator)=>{
            operatorOptions.push({
                value:operator,
                label:operator
            });
        });
        return operatorOptions;
    }

    getDroppingPointsOptions = (nextProps) => {

        let droppingPoints = new Set();
        nextProps.tripList.forEach((trip)=>{
            if(Array.isArray(trip.droppingTimes)){
                trip.droppingTimes.forEach((droppingPoint)=>{
                    droppingPoints.add(droppingPoint.address ? droppingPoint.address : droppingPoint.landmark);
                });
            } else {
                if(trip.droppingTimes){
                    droppingPoints.add(trip.droppingTimes.address ? trip.droppingTimes.address : trip.droppingTimes.landmark);
                }
            }
        });

        let droppingPointOptions = [];
        droppingPoints.forEach((droppingPoint)=>{
            droppingPointOptions.push({
                value:droppingPoint,
                label:droppingPoint
            });
        });

        return droppingPointOptions;
    }

    getBoardingPointsOptions = (nextProps) => {

        let boardingPoints = new Set();
        nextProps.tripList.forEach((trip)=>{
            if(Array.isArray(trip.boardingTimes)){
                trip.boardingTimes.forEach((boardingPoint)=>{
                    boardingPoints.add(boardingPoint.address ? boardingPoint.address : boardingPoint.landmark);
                });
            } else {
                boardingPoints.add(trip.boardingTimes.address ? trip.boardingTimes.address : trip.boardingTimes.landmark);
            }
        });

        let boardingPointOptions = [];
        boardingPoints.forEach((boardingPoint)=>{
            boardingPointOptions.push({
                value:boardingPoint,
                label:boardingPoint
            });
        });
        return boardingPointOptions;
    }
    
    applyFilter = (filters) => {
        let filteredTrips = this.props.tripList.filter((trip)=>{
            if(this.filterByOperators(trip,filters) && this.filterByBusType(trip,filters) && this.filterByBoardingPoint(trip,filters) && this.filterByDroppingPoint(trip,filters)){
                return true;
            }
            return false;
        });
        
        this.setState({
            isTripDataFiltered:true,
            tripList:filteredTrips
        });
    }

    sortTrips = (property, order) => {
        
        this.setState({
            sortOption:property + ':' + order
        });
        
        const tripsx = this.state.tripList.map((trip)=>{
            trip[property] = (property === 'travels') ? trip[property] : parseInt(trip[property]);
            return trip;
        });
        
        this.setState({
            tripList: _.orderBy(tripsx,[property], order)
        });
    }

    filterByDepartureTime(trip){

        if(0===this.state.filters.departureTime.length){
            return true;
        }

        let matchedTimes = this.state.filters.departureTime.filter((time)=>{

            let slot = time.value.split('-');

            let tripTime = moment().startOf('day').add(trip.departureTime, 'minutes');

            let startTime = moment(slot[0],'HH:mm');

            let endTime = moment(slot[1],'HH:mm');

            return tripTime.isBetween(startTime,endTime);

        });
        return matchedTimes.length > 0;
    }

    filterByDroppingPoint = (trip,filters) => {

        if(0===filters.droppingPoints.length){
            return true;
        }

        let filteredDroppingPoints = filters.droppingPoints.map((dp)=>{
            return dp.value;
        });

        let droppingTimes = [];

        if(Array.isArray(trip.droppingTimes)){
            trip.droppingTimes.map((bt)=>{
                droppingTimes.push(bt.address ? bt.address : bt.landmark);
            });
        } else{
            if(trip.droppingTimes) {
                droppingTimes.push(trip.droppingTimes.address ? trip.droppingTimes.address : trip.droppingTimes.landmark);
            }
        }

        if(_.intersection(filteredDroppingPoints,droppingTimes).length > 0){
            return true;
        } else{
            return false;
        }

    }

    filterByBoardingPoint=(trip,filters)=>{

        if(0===filters.boardingPoints.length){
            return true;
        }

        let filteredBoardingPoints = filters.boardingPoints.map((bp)=>{
            return bp.value;
        });

        let boardingTimes = [];

        if(Array.isArray(trip.boardingTimes)){
            trip.boardingTimes.map((bt)=>{
                boardingTimes.push(bt.address ? bt.address : bt.landmark);
            });
        } else {
            if(trip.boardingTimes) {
                boardingTimes.push(trip.boardingTimes.address ? trip.boardingTimes.address : trip.boardingTimes.landmark);
            }
        }

        if(_.intersection(filteredBoardingPoints,boardingTimes).length > 0){
            return true;
        } else{
            return false;
        }

    }

    filterByBusType=(trip,filters)=>{
        if(0===filters.busTypes.length){
            return true;
        }
        else if(_.find(filters.busTypes,{value:trip.busType})){
            return true;
        } else{
            return false;
        }
    }

    filterByOperators=(trip,filters)=>{
        if(0===filters.operators.length){
            return true;
        }
        else if(_.find(filters.operators,{value:trip.travels})){
            return true;
       } else{
           return false;
       }
    }
    
    render() {
        // if(this.props.tripList.length === 0){
        //     return('Loading.......')
        // }

        return (
            <React.Fragment>
                <div className="container bus-category-list">
                    <div>
                        <div className="list-container col-12 py-1">
                            <div className="row mt-4">
                                <div className="col-md-3 col-sm-4 pr-sm-0">
                                    <BusSearchBar filterOptions={this.state.filterOptions} tripListMsg={this.props.tripListMsg} isTripDataFiltered={this.state.isTripDataFiltered} 
                                        onFilter={(filter)=>{
                                            this.applyFilter(filter)
                                        }}
                                    />
                                </div>
                                <div className="col-md-9 col-sm-8 col-12">
                                    <div className="col-md-12 col-12 bus-sorting-bar">
                                    <div className="col p-s-2 cursor-ponter"><span onClick={('travels:desc'===this.state.sortOption) ? this.sortTrips.bind(this,'travels','asc') : this.sortTrips.bind(this,'travels','desc')}>Travels Name</span> <i className="material-icons">{'travels:desc'===this.state.sortOption ? "arrow_upward" : "arrow_downward"}</i> </div>
                                        <div className="col p-s-2 cursor-ponter"><span onClick={('departureTime:desc'===this.state.sortOption) ? this.sortTrips.bind(this,'departureTime','asc') : this.sortTrips.bind(this,'departureTime','desc')}>Departure Time</span> <i className="material-icons">{'departureTime:desc'===this.state.sortOption ? "arrow_upward" : "arrow_downward"}</i> </div>
                                        <div className="col p-s-2 cursor-ponter"><span onClick={('fare:desc'===this.state.sortOption) ? this.sortTrips.bind(this,'fare','asc') : this.sortTrips.bind(this,'fare','desc')}>Price</span> <i className="material-icons">{'fare:desc'===this.state.sortOption ? "arrow_upward" : "arrow_downward"}</i></div>
                                        <div className="col p-s-2 cursor-ponter"><span onClick={('availableSeats:desc'===this.state.sortOption) ? this.sortTrips.bind(this,'availableSeats','asc') : this.sortTrips.bind(this,'availableSeats','desc')}>Available Seat</span> <i className="material-icons">{'availableSeats:desc'===this.state.sortOption ? "arrow_upward" : "arrow_downward"}</i></div>
                                    </div>
                                    <BusSearchList searchParam={this.props.searchParam} tripListMsg={this.props.tripListMsg} tripList={this.state.tripList} isTripDataFiltered={this.state.isTripDataFiltered} history={this.props.history} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}