import React from 'react';
import Config from '../../Config';
import {Button, Intent} from "@blueprintjs/core";
import LoadingTicket from "./LoadingTicket";
import { withRouter } from 'react-router-dom';
import {numberFormat} from './../../CommonFunction';

class Ticket extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loadingData: true,
            loadingBtnStatus: false,
            ticketNumber:'',
            mobileNumber:'',
            ticketDetail:'',
        }
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            loadingData: false,
            loadingBtnStatus:false,
            ticketDetail: nextProps.ticketDetail,
        })
    }
    
    componentDidMount() {
        let ticketParam = {
            booking_id:this.props.bookingId
        }
        this.setState({loadingBtnStatus: true});
        this.props.getTicketDetail(ticketParam)
    }

    downloadTicket = () => {
        window.location.href = Config.API_URL + 'ticket-download/'+this.state.ticketDetail.booking_id;
    }

    redirectHistory = () => {
        this.props.history.push('/order-history');
    }

    render(){
        return(
            <div className="ticket-info-container">
            {
                this.state.loadingData === false && this.state.ticketDetail ?
                    <React.Fragment>
                            <div className="ticket-detail mt-3 border rounded p-4">
                                <div className="row">
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 mt-4">
                                        <h6>Booking ID</h6>
                                        <p>{this.state.ticketDetail.booking_id}</p>

                                        <div className="mt-4">
                                            <h6>Bus Name</h6>
                                            <p>{this.state.ticketDetail.travels}</p>
                                        </div>
                                        
                                        <div className="mt-4">
                                            <h6>Pickup Location</h6>
                                            <p>{this.state.ticketDetail.pickup_location}</p>
                                        </div>

                                        {
                                            (this.state.ticketDetail.convenience_charge > 0) ? 
                                                <div className="mt-4">
                                                    <h6>Base Amount</h6>
                                                    <p>{ numberFormat(this.state.ticketDetail.base_fare) }</p>
                                                </div>
                                            :
                                                ''
                                        }

                                        <div className="mt-4">
                                            <h6>Coin Discount</h6>
                                            <p>{this.state.ticketDetail.coin_discount ? numberFormat(this.state.ticketDetail.coin_discount) : 0 }</p>
                                        </div>
                                    </div>
                                    
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 mt-4">
                                        <h6>Ticket ID</h6>
                                        <p>{this.state.ticketDetail.tin}</p>
                                        
                                        <div className="mt-4">
                                            <h6>Bus Type</h6>
                                            <p>{this.state.ticketDetail.bus_type}</p>
                                        </div>

                                        <div className="mt-4">
                                            <h6>Pickup Time</h6>
                                            <p>{this.state.ticketDetail.pickup_time}</p>
                                        </div>

                                        {
                                            (this.state.ticketDetail.convenience_charge > 0) ? 
                                                <div className="mt-4">
                                                    <h6>Convenience Charge</h6>
                                                    <p>{ numberFormat(this.state.ticketDetail.convenience_charge) }</p>
                                                </div>
                                            :
                                                ''
                                        }

                                        <div className="mt-4">
                                            <h6>Paid Amount</h6>
                                            <p>{ this.state.ticketDetail.total_amount ? numberFormat(this.state.ticketDetail.total_amount) : 0 }</p>
                                        </div>
                                    </div>
                                    
                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-5 mt-4 ">
                                        <h6>Journey Station</h6>
                                        <p>{this.state.ticketDetail.source_city + ' to '+ this.state.ticketDetail.destination_city}</p>
                                        
                                        <div className="mt-4">
                                            <h6>Journey Date</h6>
                                            <p>{this.state.ticketDetail.doj}</p>
                                        </div>

                                        <div className="mt-4">
                                            <h6>Pickup Contacts</h6>
                                            <p>{this.state.ticketDetail.pickup_contact_no}</p>
                                        </div>

                                        {
                                            (this.state.ticketDetail.convenience_charge > 0) ? 
                                                <div className="mt-4">
                                                    <h6>Payment Charge</h6>
                                                    <p>{ numberFormat(this.state.ticketDetail.payment_gateway_charge) }</p>
                                                </div>
                                            :
                                                ''
                                        }
                                    
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="table-responsive passenger-table text-center pt-3 pb-3 mx-3">
                                        <table className="w-100">
                                        <thead>
                                            <tr>
                                                <th scope="col">No.</th>
                                                <th scope="col">Passenger name</th>
                                                <th scope="col">Age</th>
                                                <th scope="col">Gender</th>
                                                <th scope="col">Seat</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {(Array.isArray(this.state.ticketDetail.seats))
                                            ? this.state.ticketDetail.seats.map((seat, index) => {

                                                    return  <tr key={index}>
                                                                <td>{ parseInt(index,10) + 1}</td>
                                                                <td>{seat.name}</td>
                                                                <td>{seat.age}</td>
                                                                <td>{seat.gender ? seat.gender : '-'}</td>
                                                                <td>{seat.seat_name}</td>
                                                            </tr>
                                                    })
                                                    : 
                                                        <tr>
                                                            <td>1</td>
                                                            <td>{this.state.ticketDetail.seats.name}</td>
                                                            <td>{this.state.ticketDetail.seats.age}</td>
                                                            <td>{this.state.ticketDetail.seats.gender}</td>
                                                            <td>{this.state.ticketDetail.seats.seat_name}</td>
                                                        </tr>
                                                    
                                                }
                                            </tbody>                              
                                        </table>
                                    </div>

                                    <div className="mt-3 ml-3">
                                        <p>Cancellation Policy :</p>
                                        {
                                            (Array.isArray(this.state.ticketDetail.seats))
                                                ? 
                                                    this.state.ticketDetail.cancellation_policy.map((data, index) => {
                                                        return  <div className="notes"><b>-></b> {data.note}</div>
                                                    })
                                                : 
                                                    ''
                                        }
                                    </div>
                                </div>
                            </div>

                        <div className="row mt-4 mb-5">
                            <div className="col-md-12 col-12">
                                <Button intent={Intent.PRIMARY} onClick={this.downloadTicket} className="ml-2 pull-right">Download Ticket</Button>
                                <Button intent={Intent.DANGER} onClick={this.redirectHistory} className="ml-2 pull-right bp3-primary">View Order History</Button>
                            </div>
                        </div>
                    </React.Fragment>
                : 
                <LoadingTicket />
            }
            </div>
        );
    }
}

export default withRouter(Ticket);