import React from 'react';
import {InputGroup, Button, Intent, Toaster,Toaster as toast, Position} from "@blueprintjs/core";

export const maxGGcoins = (baseFare) => {
    baseFare = parseFloat(baseFare) * 0.05;
    return Math.floor(baseFare);
}

export default class RedeemCoin extends React.Component {
    
    constructor(props){
        super(props);
        this.state = {
            loadingBtnStatus: false,
            ggCoins:'',
            applyGgCoins:0,
        }
    }

    componentDidMount() {
       
    }

    removeCoins = () => {
        this.setState({
            ggCoins:'',
            applyGgCoins:null
        },() =>{
            console.log(this.state.ggCoins, "coins")
        })
        this.redeemCoins()
    }

    redeemCoins = () => {
		
		if (!/^[\d]+$/.test(this.state.ggCoins)) {
			toast.create({position: Position.TOP}).show({message:'Please enter valid GG Coins.',intent: Intent.DANGER});
			return false;
		}

		let coinLimit = maxGGcoins(this.props.selectedSeats.reduce(function(totalFare, seat){ return totalFare + parseInt(seat.fare)},0));
		if (coinLimit < this.state.ggCoins) {
			toast.create({position: Position.TOP}).show({message:'You can redeem maximum '+coinLimit+' coins.', intent: Intent.DANGER});
			return false;
		}

        this.setState({
            loadingBtnStatus:true
        })

		this.props.checkGGCoinBalance()
        .then((res)=>{

            this.setState({
                loadingBtnStatus:false
            })

			let balanceCoins=0;
			if(res.status===true){
				balanceCoins=res.data.coin_balance;
				if(balanceCoins < this.state.ggCoins) {
					toast.create({position: Position.TOP}).show({message:'Insufficient coin balance, Your coin balance is '+balanceCoins,intent: Intent.DANGER});
					return false;
				} else {
					this.setState({
						applyGgCoins:this.state.ggCoins
					}, () =>{
                        this.props.applyCoin(this.state.applyGgCoins)
                    });
				}
			}else{
				Toaster.create({position: Position.TOP}).show({message:res.data.message,intent: Intent.DANGER});
			}
		})
		.catch(
            this.setState({
                loadingBtnStatus:false
            })
        );
	}

    render(){
        return(
            <div className="col-md-12 coin-section col-12 d-flex mt-2 mb-3">
                <div className="col-md-8 col-sm-8 pl-0">
                    <InputGroup 
                        type='text' 
                        name='gg-coin'
                        className="coin-input"
                        placeholder="Enter GG Coin"
                        disabled={this.state.applyGgCoins > 0 ? true : false}
                        value={this.state.ggCoins}
                        onChange={(event)=>{
                            this.setState({
                                ggCoins:event.target.value
                            })
                        }}
                    />
                    <sub className="pl-0">(Maximum {this.props.maxCoinLimit} GG-Coins can be used)</sub>
                </div>
                <div className="col-md-4 col-sm-4 payment-value">
                {
                    this.state.applyGgCoins > 0 ?
                        <Button className="bp3-button bp3-small redeem-btn pull-right" loading={this.state.loadingBtnStatus === false ? false : true} onClick={this.removeCoins} intent={Intent.DANGER}>Remove</Button>
                    :
                        <Button className="bp3-button bp3-small redeem-btn pull-right" loading={this.state.loadingBtnStatus === false ? false : true} onClick={this.redeemCoins} intent={Intent.PRIMARY}>Redeem</Button>
                }   
                </div>
            </div>
        );
    }
}