import React from 'react';
import {Checkbox} from "@blueprintjs/core";
import LoadingBusSearch from "./LoadingBusSearch";
import _ from 'lodash';

export default class BusSearchBar extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            loadingData: true,
            filterStatus:false,
            
            operators:[],
            busTypes:[],
            boardingPoints:[],
            droppingPoints:[],
            
            sortOption:null,
            
            filterOptions:{
                operators:[],
                busTypes:[],
                boardingPoints:[],
                droppingPoints:[]
            }
        }
    }

    componentDidMount(){
        if(this.props.isTripDataFiltered) {
            this.setState({
                loadingData: false,
                filterOptions:this.props.filterOptions
            });
        } else {
            setTimeout(() => {
                this.setState({
                    loadingData: false,
                    filterOptions:this.props.filterOptions
                });
            }, 1000);
        }
    }

    componentWillReceiveProps(nextProps) {

        if(nextProps.isTripDataFiltered) {
            this.setState({
                loadingData: false,
                filterOptions:nextProps.filterOptions
            });
        } else {
            setTimeout(() => {
                this.setState({
                    loadingData: false,
                    filterOptions:nextProps.filterOptions
                });
            }, 1000);
        }
    }

    handleBusTypeChange=(checked,obj,index)=>{ 

        let busTypes = this.state.busTypes;
        
        if (checked===true) {
            busTypes.push(obj);
        } else {
            _.remove(busTypes, function (e) {
                return e.value === obj.value;
            });
        }
        
        this.setState({
            busTypes:busTypes
        },()=>{
            this.applyFilter()
        })
    }

    handleOperatorChange=(checked,obj,index)=>{ 

        let operators = this.state.operators;
        
        if (checked===true) {
            operators.push(obj);
        } else {
            _.remove(operators, function (e) {
                return e.value === obj.value;
            });
        }
        
        this.setState({
            operators:operators
        },()=>{
            this.applyFilter()
        })
    }

    handleBoardingPointChange=(checked,obj,index)=>{ 

        let boardingPoints = this.state.boardingPoints;
        
        if (checked===true) {
            boardingPoints.push(obj);
        } else {
            _.remove(boardingPoints, function (e) {
                return e.value === obj.value;
            });
        }
        
        this.setState({
            boardingPoints:boardingPoints
        },()=>{
            this.applyFilter()
        })
    }

    handleDroppingPointChange=(checked,obj,index)=>{ 

        let droppingPoints = this.state.droppingPoints;
        
        if (checked===true) {
            droppingPoints.push(obj);
        } else {
            _.remove(droppingPoints, function (e) {
                return e.value === obj.value;
            });
        }
        
        this.setState({
            droppingPoints:droppingPoints
        },()=>{
            this.applyFilter()
        })
    }

    applyFilter=()=>{
        
        let filter = {};
        
        if(this.state.operators.length > 0) {
            filter.operators=this.state.operators;
        } else {
            filter.operators=this.state.filterOptions.operators;
        }

        if(this.state.busTypes.length > 0) {
            filter.busTypes=this.state.busTypes;
        } else {
            filter.busTypes=this.state.filterOptions.busTypes;
        }

        if(this.state.boardingPoints.length > 0) {
            filter.boardingPoints=this.state.boardingPoints;
        } else {
            filter.boardingPoints=this.state.filterOptions.boardingPoints;
        }

        if(this.state.droppingPoints.length > 0) {
            filter.droppingPoints=this.state.droppingPoints;
        } else {
            filter.droppingPoints=this.state.filterOptions.droppingPoints;
        }

        this.props.onFilter(filter);
    }

    clearFilter=()=>{
        this.setState({
            operators:[],
            busTypes:[],
            boardingPoints:[],
            droppingPoints:[],
            filterOptions:{
                operators:[],
                busTypes:[],
                boardingPoints:[],
                droppingPoints:[]
            }
        },()=>{
            this.applyFilter();
        })
    }

    render(){
        return(
            <div className="bus-sidebar">
                <div className="row">
					<div className="col-12">
						<span onClick={()=>{this.clearFilter()}} className="text-center see-all" style={{'cursor':'pointer'}} >Reset All</span>
					</div>
				</div>
                <article>
                    <div className="ar-header">
                        <label className="bp3-label sb-title">Bus Types</label>
                    </div>
                    <div className="ar-body fix-overflow">
                        {   
                            this.state.loadingData === true ?
                                <LoadingBusSearch />
                            :
                                this.state.filterOptions.busTypes.length > 0 ?
                                    this.state.filterOptions.busTypes.map((btype, key) => {
                                        return <Checkbox 
                                            className="sb-chk-text"
                                            checked={btype.checked} 
                                            key={key}
                                            onChange={(e)=>{
                                                this.handleBusTypeChange(e.target.checked,btype,key)
                                            }}
                                            >{btype.value}</Checkbox>
                                    })
                                :
                                    this.props.tripListMsg !== "No bus found" ? 
                                        <LoadingBusSearch />
                                    :
                                        <div className="no-record"> No option Found.</div>
                        }
                    </div>
                </article>

                <article>
                    <div className="ar-header">
                        <label className="bp3-label sb-title">Operators </label>
                    </div>
                    <div className="ar-body fix-overflow">
                        {   
                            this.state.loadingData === true ?
                                <LoadingBusSearch />
                            :
                                this.state.filterOptions.operators.length > 0 ?
                                    this.state.filterOptions.operators.map((operator, key) => {
                                        return <Checkbox 
                                            className="sb-chk-text"
                                            checked={operator.checked} 
                                            key={key} 
                                            onChange={(e)=>{
                                                this.handleOperatorChange(e.target.checked,operator,key)
                                            }}
                                            >{operator.value}</Checkbox>
                                    })
                                :
                                    this.props.tripListMsg !== "No bus found" ? 
                                        <LoadingBusSearch />
                                    :
                                        <div className="no-record"> No option Found.</div>
                        }
                    </div>
                </article>

                <article>
                    <div className="ar-header">
                        <label className="bp3-label sb-title">Boarding Points </label>
                    </div>
                    <div className="ar-body fix-overflow">
                        {   
                            this.state.loadingData === true ?
                                <LoadingBusSearch />
                            :
                                this.state.filterOptions.boardingPoints.length > 0 ? 
                                    this.state.filterOptions.boardingPoints.map((bpoint, key) => {
                                        return <Checkbox 
                                            className="sb-chk-text" 
                                            checked={bpoint.checked}
                                            key={key}
                                            onChange={(e)=>{
                                                this.handleBoardingPointChange(e.target.checked,bpoint,key)
                                            }}
                                            >{bpoint.value}</Checkbox>
                                    })
                                :
                                    this.props.tripListMsg !== "No bus found" ? 
                                        <LoadingBusSearch />
                                    :
                                        <div className="no-record"> No option Found.</div>
                        }
                    </div>
                </article>

                <article>
                    <div className="ar-header">
                        <label className="bp3-label sb-title">Dropping Points </label>
                    </div>
                    <div className="ar-body fix-overflow">
                        {   
                            this.state.loadingData === true ?
                                <LoadingBusSearch />
                            :
                                this.state.filterOptions.droppingPoints.length > 0 ? 
                                    this.state.filterOptions.droppingPoints.map((dpoint, key) => {
                                        return <Checkbox 
                                            className="sb-chk-text"
                                            checked={dpoint.checked}
                                            key={key}
                                            onChange={(e)=>{
                                                this.handleDroppingPointChange(e.target.checked,dpoint,key)
                                            }}
                                            >{dpoint.value}</Checkbox>
                                    })
                                :
                                    this.props.tripListMsg !== "No bus found" ? 
                                        <LoadingBusSearch />
                                    :
                                        <div className="no-record"> No option Found.</div>
                        }
                    </div>
                </article>
            </div>
        );
    }
}