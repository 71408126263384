import React from 'react';

export default class LoadingBookingSearch extends React.Component {
    render(){
        return(
            <React.Fragment>
                <div className="col-md-4 col-xl-4 col-lg-4 col-sm-6 col-12 mb-4">
                    <div className="bp3-skeleton" style={{width: "100%", height: "100px"}}>&nbsp;</div>
                </div>

                <div className="col-md-4 col-xl-4 col-lg-4 col-sm-6 col-12 mb-4">
                    <div className="bp3-skeleton" style={{width: "100%", height: "100px"}}>&nbsp;</div>
                </div>

                <div className="col-md-4 col-xl-4 col-lg-4 col-sm-6 col-12 mb-4">
                    <div className="bp3-skeleton" style={{width: "100%", height: "100px"}}>&nbsp;</div>
                </div>
            </React.Fragment>
        );
    }
}