import React from 'react';
import { Button, Intent } from "@blueprintjs/core";

export default class LoadingPaymentDetail extends React.Component {

    render(){
        return(
            <div className="payment-detail py-2 bp3-skeleton">
                <div className="col-md-12 col-12  d-flex">
                    <h1 className="bp3-text-xlarge heading pt-xl-2 pt-lg-2 pt-md-2 pt-sm-0"> Payable amount</h1>
                </div>

                <div className="col-md-12 col-12 d-flex">
                    <div className="col-md-8 payment-label">Basic fare</div>
                    <div className="col-md-4 payment-value">₹ 465.00</div>
                </div>

                <div className="col-md-12 col-12 d-flex">
                    <div className="col-md-8 payment-label">Payment gateway charge</div>
                    <div className="col-md-4 payment-value">₹ 465.00</div>
                </div>

                <div className="col-md-12 col-12 d-flex">
                    <div className="col-md-8 payment-label">Convenience charge</div>
                    <div className="col-md-4 payment-value">₹ 465.00</div>
                </div>
                
                <div className="col-md-12 col-12 d-flex">
                    <div className="col-md-8 payment-label">Sub Total</div>
                    <div className="col-md-4 payment-value">₹ 465.00</div>
                </div>
                
                <div className="col-md-12 col-12 d-flex">
                    <div className="col-md-12 col-12 border-top"></div>	
                </div>

                <div className="col-md-12 col-12 d-flex mt-2">
                    <div className="col-md-8 coin">GG Coin<br/><sub>Maximum 35 GG-Coins can be used</sub></div>
                    <div className="col-md-4 payment-value">₹ 465.00</div>
                </div>

                <div className="col-md-12 col-12 d-flex">
                    <div className="col-md-6 payment-label"><span>Grand Total</span></div>
                    <div className="col-md-6 payment-value"><span>₹ 465.00</span></div>
                </div>

                <div className="col-md-12 col-12 mt-3">
                    <Button className="bp3-button pull-right bus-payment" intent={Intent.PRIMARY}>Proceed to Pay</Button>
                </div><br/>
            </div>
        );
    }
}