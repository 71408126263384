const APP_ENV = 'PRODUCTION'; // local,DEV,PRODUCTION
const APP_EXTENSION = '.com';
const MAINTENANCE_MODE = false;

const config = {

    ENV                 : APP_ENV,
    IS_MAINTENANCE_MODE : MAINTENANCE_MODE,
    EXTENSION           : APP_EXTENSION,

    APP_URL             : 'https://bus.globalgarner.com/',
    API_URL             : 'https://bus-api.globalgarner.com/api/v3/',
};

if(APP_ENV === 'local'){
    config.APP_URL              = 'http://localhost:3000/';
    config.API_URL              = 'http://localhost/bus_api_laravel/public/api/v3/';    
}

if(APP_ENV === 'DEV'){
    config.APP_URL              = 'https://bus.globalgarner.in/';
    config.API_URL              = 'https://bus-api.globalgarner.in/api/v3/';
}

export const packageConfigure={
    APP_URL             : config.APP_URL,
    APP_ENV             : config.ENV,
    Extension           : config.EXTENSION,
}

config.REACT_APP_GOOGLE_ANALYTICAL_CODE='UA-92068611-22';
config.REACT_APP_SENTRY_URL='https://907e0fae679340c387ac77b2da95a8be@sentry.io/1413279';

export default config;