import React from 'react';
import Config from '../../Config';
import { Auth } from 'gg-react-utilities';
import {ssoInstance} from 'gg-react-utilities';
import {InputGroup,Button, Intent, Toaster, Position} from "@blueprintjs/core";
import {numberFormat} from './../../CommonFunction';

export default class GetTicket extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loadingData: true,
            loadingBtnStatus: false,
            loadingCancelTicket: false,
            isLoggedIn: false,
            ticketNumber:'',
            mobileNumber:'',
            ticketDetail:''
        }
        this.ShowTicket = this.ShowTicket.bind(this);
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            loadingData: false,
            loadingBtnStatus:false,
            ticketDetail: nextProps.ticketDetail,
        })
    }

    async componentDidMount() {
        this.setState({
            isLoggedIn: (await Auth.isLoggedIn() === true) ? true : false
        })
    }

    renderBookingStatus(status, statusType){
        switch (status){
            case 0:
                return <span className="badge badge-warning font-14">{statusType}</span>;
            case 1:
                return <span className="badge badge-warning font-14">{statusType}</span>;
            case 2:
                return <span className="badge badge-success font-14">{statusType}</span>;
            case 3:
                return <span className="badge badge-danger font-14">{statusType}</span>;
            case 4:
                return <span className="badge badge-danger font-14">{statusType}</span>;
            case 5:
                return <span className="badge badge-success font-14">{statusType}</span>;
            default:
                return "";
        }
    }

    ShowTicket() {
        if(this.state.ticketNumber==='') {
			Toaster.create({position: Position.TOP}).show({message:'Please enter ticket number.',intent: Intent.DANGER});
			return false;
        }

        if (!/^\d{10}$/.test(this.state.mobileNumber)) {
			Toaster.create({position: Position.TOP}).show({message:'Please enter valid booking mobile no.',intent: Intent.DANGER});
            return false;
		}
        
        let ticketParam = {
            ticket_no:this.state.ticketNumber,
            mobile_no:this.state.mobileNumber
        }
        this.setState({loadingBtnStatus: true});
        this.props.getTicketDetail(ticketParam)
            .then(()=>{
                this.setState({
                    loadingData: false,
                    loadingBtnStatus:false
                });
            }).catch(()=>{
                this.setState({
                    loadingData: false,
                    loadingBtnStatus:false
                });
            });
    }

    downloadTicket = () => {
        window.location.href = Config.API_URL + 'ticket-download/'+this.state.ticketDetail.booking_id;
    }
    
    cancelTicket = (e) => {
        let param = {
            booking_id : this.state.ticketDetail.booking_id
        }

        this.setState({loadingCancelTicket: true});
        
        ssoInstance.post(Config.API_URL + 'cancel-ticket', param).then(res => {

            this.setState({loadingCancelTicket: false});
            const resdata = res.data;
            if (resdata.status === true) {
                Toaster.create({position: Position.TOP,}).show({message: resdata.message, intent: Intent.SUCCESS});
                setTimeout(function(){ window.location.href = Config.APP_URL; }, 3000);
            } else {
                Toaster.create({position: Position.TOP,}).show({message: resdata.message, intent: Intent.DANGER});
            }
        }).catch((error) => {
            this.setState({loadingCancelTicket: false});
            const sources = error.response
            if (sources.data.message === "Validation Error") {
                sources.data.error.map((res) => {
                    Toaster.create({position: Position.TOP}).show({message:res,intent: Intent.DANGER});
                    return res
                })
            } else {
                Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
            }
        });
    }

    render(){
        return(
            <div className="ticket-info-container">
                <div className="row">
                    <div className="col-md-12 col-12">
                        <div className="row">
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12"><InputGroup placeholder="Enter Ticket Number" onChange={(e) => {
                            this.setState({
                                ticketNumber:e.target.value
                            });
                        }}/></div>
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-s-3 mt-md-0 mt-lg-0 mt-xl-0"><InputGroup placeholder="Enter Mobile Number" onChange={(e) => {
                            this.setState({
                                mobileNumber:e.target.value
                            });
                        }}/></div>
						</div>
                    </div>

                    <div className="col-md-12 col-12 mt-4">
                        <Button intent={Intent.PRIMARY} className="mr-s-0 pull-right" loading={this.state.loadingBtnStatus === false ? false : true} onClick={this.ShowTicket}>Submit</Button>
                    </div>
                </div>

                {
                    this.state.loadingData === false && this.state.ticketDetail ?

                        <div className="ticket-detail mt-3">                
                            <React.Fragment>
                                <div className="row">
                                    <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-12 mt-4">
                                        <h6>Booking ID</h6>
                                        <p>{this.state.ticketDetail.booking_id}</p>

                                        <div className="mt-4">
                                            <h6>Bus Name</h6>
                                            <p>{this.state.ticketDetail.travels}</p>
                                        </div>
                                        
                                        <div className="mt-4">
                                            <h6>Pickup Location</h6>
                                            <p>{this.state.ticketDetail.pickup_location}</p>
                                        </div>

                                        {
                                            (this.state.ticketDetail.convenience_charge > 0) ? 
                                                <div className="mt-4">
                                                    <h6>Base Amount</h6>
                                                    <p>{ numberFormat(this.state.ticketDetail.base_fare) }</p>
                                                </div>
                                            :
                                                ''
                                        }

                                        <div className="mt-4">
                                            <h6>Coin Discount</h6>
                                            <p>{this.state.ticketDetail.coin_discount ? numberFormat(this.state.ticketDetail.coin_discount) : 0 }</p>
                                        </div>
                                    </div>
                                    
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-7 mt-4">
                                        <h6>Ticket ID</h6>
                                        <p>{this.state.ticketDetail.tin}</p>
                                        
                                        <div className="mt-4">
                                            <h6>Bus Type</h6>
                                            <p>{this.state.ticketDetail.bus_type}</p>
                                        </div>

                                        <div className="mt-4">
                                            <h6>Pickup Time</h6>
                                            <p>{this.state.ticketDetail.pickup_time}</p>
                                        </div>

                                        {
                                            (this.state.ticketDetail.convenience_charge > 0) ? 
                                                <div className="mt-4">
                                                    <h6>Convenience Charge</h6>
                                                    <p>{ numberFormat(this.state.ticketDetail.convenience_charge) }</p>
                                                </div>
                                            :
                                                ''
                                        }
                                        <div className="mt-4">
                                            <h6>Paid Amount</h6>
                                            <p>{this.state.ticketDetail.total_amount ? numberFormat(this.state.ticketDetail.total_amount) : 0 }</p>
                                        </div>
                                    </div>
                                    
                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-5 mt-4 text-right text-none pl-s-2">
                                        <h6>Journey Station</h6>
                                        <p>{this.state.ticketDetail.source_city + ' to '+ this.state.ticketDetail.destination_city}</p>
                                        
                                        <div className="mt-4">
                                            <h6>Journey Date</h6>
                                            <p>{this.state.ticketDetail.doj}</p>
                                        </div>

                                        <div className="mt-4">
                                            <h6>Pickup Contacts</h6>
                                            <p>{this.state.ticketDetail.pickup_contact_no}</p>
                                        </div>

                                        {
                                            (this.state.ticketDetail.convenience_charge > 0) ? 
                                                <div className="mt-4">
                                                    <h6>Payment Charge</h6>
                                                    <p>{ numberFormat(this.state.ticketDetail.payment_gateway_charge) }</p>
                                                </div>
                                            :
                                                ''
                                        }

                                        <div className="mt-4">
                                            <h6>Booking Status</h6>
                                            <p>{this.renderBookingStatus(this.state.ticketDetail.status, this.state.ticketDetail.status_type)}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-5 mb-3">
                                    <div className="table-responsive passenger-table text-center pt-3 pb-3">
                                        <table className="w-100">
                                            <thead>
                                            <tr>
                                                <th scope="col">No.</th>
                                                <th scope="col">Passenger name</th>
                                                <th scope="col">Age</th>
                                                <th scope="col">Gender</th>
                                                <th scope="col">Seat</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {(Array.isArray(this.state.ticketDetail.seats))
                                            ? this.state.ticketDetail.seats.map((seat, index) => {

                                                    return  <tr key={index}>
                                                                <td>{ parseInt(index,10) + 1}</td>
                                                                <td>{seat.name}</td>
                                                                <td>{seat.age}</td>
                                                                <td>{seat.gender ? seat.gender : '-'}</td>
                                                                <td>{seat.seat_name}</td>
                                                            </tr>
                                                    })
                                                    : 
                                                        <tr>
                                                            <td>1</td>
                                                            <td>{this.state.ticketDetail.seats.name}</td>
                                                            <td>{this.state.ticketDetail.seats.age}</td>
                                                            <td>{this.state.ticketDetail.seats.gender}</td>
                                                            <td>{this.state.ticketDetail.seats.seat_name}</td>
                                                        </tr>
                                                    
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="mt-3">
                                        <p>Cancellation Policy :</p>
                                        {
                                            (Array.isArray(this.state.ticketDetail.seats))
                                                ? 
                                                    this.state.ticketDetail.cancellation_policy.map((data, index) => {
                                                        return  <div className="notes" key={index}><b>-></b> {data.note}</div>
                                                    })
                                                : 
                                                    ''
                                        }
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-md-12 col-12">
                                        {
                                            (this.state.isLoggedIn === true && this.state.ticketDetail.show_cancel_btn === true) ? 
                                                <Button intent={Intent.DANGER} loading={this.state.loadingCancelTicket === false ? false : true} onClick={(e) => { if (window.confirm('Are you sure to cancel this ticket ?')) this.cancelTicket(e) }}  className="ml-2 pull-right">Cancel Ticket</Button>
                                            :
                                                ''
                                        }
                                        <Button intent={Intent.PRIMARY} onClick={this.downloadTicket} className="ml-2 pull-right">Download Ticket</Button>
                                    </div>
                                </div>
                            </React.Fragment>
                        </div>
                    : ''
                }
            </div>
        );
    }
}