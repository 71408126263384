export const sourceCity = (sources) => {
    return {
        type: 'GET_SOURCE_CITY_LIST',
        sources: sources
    }
};

export const ticketDetailAction = (sources) => {
    return {
        type: 'TICKET_DETAIL',
        sources:sources
    }
}

export const bookingHistoryAction = (sources) => {
    return {
        type: 'BOOKING_HISTORY',
        sources:sources
    }
}

export const bookingDetailAction = (sources) => {
    return {
        type: 'BOOKING_DETAIL',
        sources:sources
    }
}

export const tripListAction = (sources, tripListMsg='') => {
    return {
        type: 'TRIP_LIST',
        sources:sources,
        tripListMsg:tripListMsg
    }
}

export const coinAction = (sources) => {
    return {
        type: 'COIN_DATA_ACTION',
        sources:sources
    }
}

export const selectSeat = (seat) => {
    return {
        type: 'SELECT_SEAT',
        seat
    }
}

export const unSelectSeat = (seat) => {
    return {
        type: 'UNSELECT_SEAT',
        seat
    }
}

export const resetSelectedSeats = () => {
    return {
        type: 'RESET_SELECTED_SEATS'
    }
}

export const initialBookingAction = (sources) => {
    return {
        type: 'INITIAL_BOOKING_HISTORY',
        sources:sources
    }
}