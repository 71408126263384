import {connect} from 'react-redux';
import { Toaster, Intent, Position } from '@blueprintjs/core';
import Trip from '../components/Trip';
import {getTripList} from '../../core/actions/action';
import {tripListAction} from '../../core/actions';

const mapStateToProps = state => {
    return{       
        tripList:state.Trip.tripList,
        tripListMsg:state.Trip.tripListMsg
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getTripList :(param) => {
            dispatch(tripListAction([]));
            return getTripList(param)
                .then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                        dispatch(tripListAction(sources.data.tripList, sources.message));
                    }else{
                        dispatch(tripListAction([]));
                        Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
                    }
                }).catch((error)=>{
                    const sources = error.response;
                    Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
                })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Trip);