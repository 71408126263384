let initialState = {
    sourceCityList: []
};

const SourceCity = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_SOURCE_CITY_LIST':
            return {...state, sourceCityList: action.sources}
        default:
            return state
    }
};
export default SourceCity;