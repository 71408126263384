import React from 'react';

export default class LoadingBusSeatLayout extends React.Component {

    render(){
        return(
            <div className="seat-deck col-12 mb-3 mr-2 bp3-skeleton" style={{'height':'400px'}}>
            </div>
        );
    }
}