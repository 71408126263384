import React from 'react';
import {Link} from "react-router-dom";
import TicketContainer from "./containers/TicketContainer";
import cancel from "./../core/images/cancel.svg";
import success from "./../core/images/success.svg";

export default class Payment extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            paymentStatus : this.props.match.params.status,
            bookingId : this.props.match.params.bookingid ? this.props.match.params.bookingid : '',
        }
    }
    
    render() {
        return (
            <React.Fragment>
               <div className="container">
                    <div className="row">
                        {
                            this.props.match.params.status === "2" ?
                                
                                <div className="col-12">
                                    <div className="col-12 border rounded mt-3 mb-2 p-3">
                                        <div className="row">
                                            <div className="col-1 mt-2"><img src={success} alt="" className="img-fluid"/></div>
                                            <div className="col-10"><h2 className="mt-3 mb-2 text-left booking-title">Your Bus Booked Successfully</h2>
                                                <p className="mt-1 text-left">Booking ID # {this.state.bookingId} has been booked succesfully and an ticket has been emailed.</p>
                                            </div>
                                        </div>
                                    </div>                                    
                                    <h2 className="mt-4 mb-0 pl-1 text-left">Booking No # {this.state.bookingId}</h2>
                                    <TicketContainer bookingId={this.state.bookingId} />
                                </div>
                                
                            :

                            this.props.match.params.status === "1" ?

                                <div className="bus-payment-section mt-5 mb-3">
                                    <div className="paymentWrapper pb-5">
                                        <div className="upperPaymentWrapper"></div>
                                        <div className="middlePaymentWrapper">
                                            <div className="circleWrapper">
                                                <div className="iconWrapper">
                                                    <img src={cancel} alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-8 lowerPaymentWrapper m-auto">
                                            <h1 className="mt-4 text-dark text-center">Your Bus Booking Confirmation has Failed.</h1>
                                            <p>Booking ID # {this.state.bookingId}, You will get your refund within 7 working days if payment success.</p>
                                            <Link to="/order-history" className="text-center view-all">View Booking History</Link>
                                        </div>
                                    </div>
                                </div>
                            :
                                this.props.match.params.status === "3" ?
                                    <div className="bus-payment-section mt-5 mb-3">
                                        <div className="paymentWrapper pb-5">
                                            <div className="upperPaymentWrapper"></div>
                                            <div className="middlePaymentWrapper">
                                                <div className="circleWrapper">
                                                    <div className="iconWrapper">
                                                        <img src={cancel} alt=""/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-8 lowerPaymentWrapper m-auto">
                                                <h3 className="mt-4">Your Bus Booking Payment is Failed.</h3>
                                                <p>Booking ID # {this.state.bookingId}, You might receive a message from your bank that the payment could not be processed.</p>
                                                <Link to="/order-history" className="text-center view-all">View Booking History</Link>
                                            </div>
                                        </div>
                                    </div>

                                :
                                    <div className="bus-payment-section mt-5 mb-3">
                                        <div className="paymentWrapper pb-5">
                                            <div className="upperPaymentWrapper"></div>
                                            <div className="middlePaymentWrapper">
                                                <div className="circleWrapper">
                                                    <div className="iconWrapper">
                                                        <img src={cancel} alt=""/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-8 lowerPaymentWrapper m-auto">
                                                <h3 className="mt-4">Invalid Booking Request</h3>
                                                <p>We can't find any booking, Please contact to Global Garner.</p>
                                                <Link to="/order-history" className="text-center view-all">View Booking History</Link>
                                            </div>
                                        </div>
                                    </div>
                        }
                    </div>
               </div>
            </React.Fragment>
        );
    }
}