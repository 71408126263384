import React from 'react';
import SingleBookingSearch from "./SingleBookingSearch";
import LoadingBookingSearch from "./LoadingBookingSearch";

const RecentSearchLimit = 6;

export default class RecentSearch extends React.Component {
    
    constructor(props){
        super(props);
        this.state = {
            loadingData: true,
            recentSearch: []
        }
    }

    componentDidMount() {
        var recentSearchData = (localStorage.recentSearch) ?  JSON.parse(localStorage.recentSearch) : null;
        setTimeout(() => {
            this.setState({
                loadingData: false,
                recentSearch: (recentSearchData != null) ? recentSearchData.sort() : []
            });
        }, 1000);
    }

    render(){
        return(
            <div className="recharge-plans recent-search text-left">
                <h2 className="bp3-text-xlarge heading mb-5 pt-2">Recent Searches</h2>
                {
                    this.state.loadingData === true ?    
                        <div className="row">
                            <LoadingBookingSearch />
                        </div>
                    :
                        this.state.recentSearch.length > 0 ?
                            <div className="row">
                                {
                                    this.state.recentSearch.slice(0, RecentSearchLimit).map((trip, key) => {
                                        return <SingleBookingSearch data={trip} key={key} divNumber={key} />
                                    })
                                }
                            </div>
                        :
                            <div className="row">
                                <div className="no-record">No recent search found</div>
                            </div>
                }
            </div>
        );
    }
}