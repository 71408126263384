import React from 'react';
import moment from 'moment';
import {Link} from "react-router-dom";

export default class ModifySearch extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            source_id:null,
            source_name:null,
            destination_id:null,
            destination_name:null,
            doj:''
        }
    }

    componentWillReceiveProps(nextProps) {
       this.setState( {
            source_id: nextProps.tripDetail.source_id,
            source_name: nextProps.tripDetail.source_name,
            destination_id: nextProps.tripDetail.destination_id,
            destination_name: nextProps.tripDetail.destination_name,
            doj: nextProps.tripDetail.doj,
        })
    }

    render(){
        return(
            <div className="trip-info-container">
                <div className="row">
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12">
                        <p className="trip-info-journey">Onward journey</p>
                        <p className="fromtodec">{this.state.source_name} <span>to</span> {this.state.destination_name}</p>
                        <p className="journey-date">Journey Date:  <span>{moment(this.state.doj).format("DD/MM/YYYY")}</span></p>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 modify-search">
						<Link to={'trip-list?source_id='+this.state.source_id +'&destination_id='+this.state.destination_id +'&source_name='+this.state.source_name +'&destination_name='+this.state.destination_name +'&doj='+this.state.doj} className="bp3-button bp3-intent-primary rounded">
                            <span className="bp3-button-text">Modify Search</span>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}