import {connect} from 'react-redux';
import { Toaster, Intent, Position } from '@blueprintjs/core';
import BookingHistory from '../components/BookingHistory';
import {getBookingHistory} from '../../core/actions/action';
import {bookingHistoryAction} from '../../core/actions';

const mapStateToProps = state => {
    return{       
        bookingHistoryList:state.Booking.bookingHistoryList,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getBookingHistory :(param) => {
            return getBookingHistory(param)
                .then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                        dispatch(bookingHistoryAction(sources.data.bookingList));
                    }else{
                        dispatch(bookingHistoryAction([]));
                        Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
                    }
                }).catch((error)=>{
                    const sources = error.response;
                    Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
                })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingHistory);