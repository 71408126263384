import {connect} from 'react-redux';
import { Toaster, Intent, Position } from '@blueprintjs/core';
import SearchBarComponent from '../components/SearchBarComponent';

import {getSourceCity} from '../../core/actions/action';
import {sourceCity} from '../../core/actions';

const mapStateToProps = state => {
    return{
        sourceCityList:state.SourceCity.sourceCityList
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getSourceCity: (input) => {
            return getSourceCity(input)
                .then(res => {
                    const sources = res.data;
                    
                    if (sources.status === true) {
                        sources.data.sourceList.map((item)=>{
                            item.value = item.id
                            item.label = item.name
                            return item;                           
                        })
                        dispatch(sourceCity(sources.data.sourceList));
                    } else {
                        dispatch(sourceCity([]));
                        Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
                    }
                })
                .catch(error => {
                    const sources = error.response;
                    Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
                });
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SearchBarComponent);
