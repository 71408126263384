import React from 'react';
import {DateInput} from "@blueprintjs/datetime";
import {Button, Intent, Position, Toaster} from "@blueprintjs/core";
import  Async  from 'react-select/async';
import moment from 'moment';

import Config from '../../Config';
import {ssoInstance} from 'gg-react-utilities';

export default class SearchBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            journeyDate: null,
            sourceCityList: [],
            source: '',
            destinationCityList: [],
            destination: '',
            inverseSearchCls:'bus-swipearrow'
        }
    }

    componentDidMount() {
        this.props.getSourceCity();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({sourceCityList: nextProps.sourceCityList})

        if (nextProps.searchParam) {

            let sparam = {
                value: nextProps.searchParam.source_id,
                label: nextProps.searchParam.source_name
            }
            this.handleSourceCitySelect(sparam)

            this.setState({
                source: {
                    label: nextProps.searchParam.source_name,
                    value: nextProps.searchParam.source_id
                },
                destination: {
                    label: nextProps.searchParam.destination_name,
                    value: nextProps.searchParam.destination_id
                },
                journeyDate: new Date(nextProps.searchParam.doj)
            })
        }
    }

    filterSourceCity = (inputSource : string) => {
        if (inputSource) {
            return this
                .state
                .sourceCityList
                .filter(i => i.label.toLowerCase().includes(inputSource.toLowerCase()));
        }
        return this.state.sourceCityList;
    };

    loadSourceCity = (inputValue, callback) => {
        setTimeout(() => {
            callback(this.filterSourceCity(inputValue));
        }, 1000);
    };

    loadDestinationCity = (inputValue, callback) => {
        setTimeout(() => {
            callback(this.filterDestinationCity(inputValue));
        }, 1000);
    };

    handleSourceCitySelect = (option) => {
        if (option != null) {
            this.setState({source: option});

            ssoInstance
                .get(Config.API_URL + 'destinations?source_id=' + option.value)
                .then(res => {
                    let destinations = res.data.data.destinationList;

                    if (Array.isArray(destinations)) {
                        destinations = destinations.map((destination) => {
                            return {value: destination.id, label: destination.name}
                        });
                    } else {
                        let dst = {
                            value: destinations.id,
                            label: destinations.name
                        }
                        destinations = [dst];
                    }

                    this.setState({destinationCityList: destinations});
                });
        }
    };

    filterDestinationCity = (inputDestination : string) => {
        if (inputDestination) {
            return this
                .state
                .destinationCityList
                .filter(i => i.label.toLowerCase().includes(inputDestination.toLowerCase()));
        }
        return this.state.destinationCityList;
    };

    handleDestinationCitySelect = (option) => {
        if (option != null) {
            this.setState({destination: option});
        }
    };

    inverseSearch = () => {
        
        let source = this.state.source;
        let destination = this.state.destination;
        let inverseSearchCls = (this.state.inverseSearchCls == "bus-swipearrow-active" ? "bus-swipearrow" : "bus-swipearrow-active"); 

        this.setState({
            source:destination,
            destination:source,
            inverseSearchCls
        });
    }

    getTrips = () => {

        if(this.state.source.label===undefined || this.state.source.label==='') {
            Toaster.create({position: Position.TOP}).show({message:'Please enter source city.',intent: Intent.DANGER});
			return false;
        }
        
        if(this.state.destination.label===undefined || this.state.destination.label==='') {
			Toaster.create({position: Position.TOP}).show({message:'Please enter destination city.',intent: Intent.DANGER});
			return false;
        }

        if(this.state.source.label === this.state.destination.label) {
            Toaster.create({position: Position.TOP}).show({message:'Please select different source and destination city.',intent: Intent.DANGER});
			return false;
        }
        
        if(this.state.journeyDate===null || this.state.destination.label==='') {
        	Toaster.create({position: Position.TOP}).show({message:'Please select journey date.',intent: Intent.DANGER});
			return false;
		}

        if (localStorage.recentSearch == null) {
            var recentSearch = [];
            var searchData = {
                source_name: this.state.source.label,
                destination_name: this.state.destination.label,
                journeydate: moment(this.state.journeyDate).format('YYYY-MM-DD'),
                source_id: this.state.source.value,
                destination_id: this.state.destination.value
            };
            recentSearch.unshift(searchData);
            localStorage.setItem("recentSearch", JSON.stringify(recentSearch));
        } else {
            recentSearch = JSON.parse(localStorage.recentSearch);
            searchData = {
                source_name: this.state.source.label,
                destination_name: this.state.destination.label,
                journeydate: moment(this.state.journeyDate).format('YYYY-MM-DD'),
                source_id: this.state.source.value,
                destination_id: this.state.destination.value
            };
            recentSearch.unshift(searchData);
            localStorage.setItem("recentSearch", JSON.stringify(recentSearch));
        }

        let tripSearchParameter = {
            source_id: this.state.source.value,
            destination_id: this.state.destination.value,
            source_name: this.state.source.label,
            destination_name: this.state.destination.label,
            doj: moment(this.state.journeyDate).format('YYYY-MM-DD')
        };

        if (this.props.isModifyPage) {
            if (this.props.onSearch) {
                this.props.onSearch(tripSearchParameter)
            }
        } else {
            var str = Object
                .keys(tripSearchParameter)
                .map(function (key) {
                    return key + '=' + tripSearchParameter[key];
                })
                .join('&');
            window.location.href = 'trip-list?' + str;
        }
    }

    render() {
        
        let maxDate = new Date();
        let year = maxDate.getFullYear();
        maxDate.setFullYear(year + 10);

        return (
            <div className="home-search-bar">
                <div className="row search-bar-container">
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-5 col-12">
                        <Async
                            multi={false}
                            value={this.state.source}
                            isLoading={true}
                            onChange={this.handleSourceCitySelect}
                            Key="id"
                            loadOptions={this.loadSourceCity}
                            placeholder={'From'}
                            defaultOptions
                            className='react-select-container'
                            classNamePrefix="react-select"
                            backspaceRemoves={true}/>
                    </div>

                    <div className="col-xl-1 col-lg-1 col-md-1 col-sm-2 col-12 p-0 pt-s-2 text-center">
                        <img onClick={this.inverseSearch} src={require('../../core/images/swipe.png')} className={this.state.inverseSearchCls}/>
                    </div>

                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-5 col-12">
                        <Async
                            multi={false}
                            value={this.state.destination}
                            isLoading={true}
                            onChange={this.handleDestinationCitySelect}
                            Key="id"
                            loadOptions={this.loadDestinationCity}
                            placeholder={'To'}
                            defaultOptions
                            className='react-select-container'
                            classNamePrefix="react-select"
                            backspaceRemoves={true}/>
                    </div>

                    <div
                        className="col-md-3 col-lg-3 col-xl-3 col-sm-6 col-12 mt-s-3 mt-sm-3 mt-md-0 pt-s-2">
                        <DateInput
                            className="bus-input"
                            closeOnSelection={true}
                            formatDate={date => date.toLocaleDateString()}
                            parseDate={str => new Date(str)}
                            onChange={(date) => {
                                this.setState({journeyDate: date})
                            }}
                            placeholder={"Select Journey Date"}
                            popoverProps={{
                                position: Position.BOTTOM
                            }}
                            showActionsBar={true}
                            todayButtonText={"Today"}
                            value={this.state.journeyDate}
                            minDate={new Date()}
                            maxDate={maxDate} 
                        />
                        <span className="bp3-icon bp3-icon-calendar inputaddon-icon"></span>
                    </div>

                    <div className="col-xl-2 col-lg-2 col-md-12 col-sm-6 col-12 mt-s-4 mt-sm-4 mt-lg-0">
                        <div className="proceed-btn float-right">
                            <Button
                                intent={Intent.PRIMARY}
                                onClick={this.getTrips}
                                className="rounded font-14">
                                {(this.props.isModifyPage)
                                    ? "Modify Search"
                                    : "Search Buses"}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}