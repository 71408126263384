import React from 'react';
import {InputGroup} from "@blueprintjs/core";

export default class LoadingPassengerForm extends React.Component {

    render(){
        return(
            <div className="col-12">
                <div className="booking-form pb-4  bp3-skeleton">
                    <div className="col-md-12 col-12">
						<div className="row">
							<div className="col-md-6 col-6">
								<h1 className="bp3-text-xlarge heading">Primary Passenger</h1>
							</div>
							<div className="col-md-6 col-6">
								<p className="seat-allocate">Seat : G46</p>
							</div>
						</div>
					</div>
                    <div className="col-md-12 col-12">
                        <div className="row">
                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 pr-s-0">
                                <InputGroup placeholder="Title" />
                            </div>
                            <div className="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9">
                                <InputGroup placeholder="Passenger Name" />
                            </div>
                        
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                <InputGroup placeholder="Email" />
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                <InputGroup placeholder="Mobile" />
                            </div>
                        
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                <InputGroup placeholder="Age" />
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                <InputGroup placeholder="Select ID" />
                            </div>
                        
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                <InputGroup placeholder="ID Number" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}