import React from 'react';
import {Link} from "react-router-dom";
import moment from 'moment';

export default class SingleBookingSearch extends React.Component {

	getDOJ(doj) {
		var dayDiff = moment().diff(doj, 'days');
		if(dayDiff >= 0) {
			doj = moment().format("YYYY-MM-DD");
		}
		return doj;
	}
	
	render(){
        return(
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 recent-search-block">
				<Link to={'trip-list?source_id='+this.props.data.source_id +'&destination_id='+this.props.data.destination_id +'&source_name='+this.props.data.source_name +'&destination_name='+this.props.data.destination_name +'&doj='+this.getDOJ(this.props.data.journeydate)}>
					<div className={"d-flex pull-right ticket-column recent-search-div-"+this.props.divNumber}>
						<div className="col-xl-10 col-10 p-3 p-s-3">
							<p>{ this.props.data.source_name ? this.props.data.source_name + ' To ' + this.props.data.destination_name : '' }</p>
							<p>{ this.props.data.journeydate ? moment(this.props.data.journeydate).format("Do MMMM YYYY") : '' }</p>
						</div>

						<div className="col-xl-2 col-2 p-0 ticket-border pull-right">
							<h5 className="ticket-book">Book</h5>
						</div>
					</div>
				</Link>
            </div>
        );
    }
}