import React from 'react';

export default class LoadingBookingHistory extends React.Component {
    render(){
        return(
            <React.Fragment>
                <div className="row no-gutters bp3-skeleton old-recharge-row col-12">
                    <div className="col-1 text-center">
                        <div className="operator-icon"><i className="material-icons">
                            directions_bus
                        </i></div>
                    </div>
                    <div className="col-3  pl-2 pt-3">
                        <div className="operator-name f-16"></div>
                    </div>
                    <div className="col-1  pl-2 pt-3">
                        <div className="mobile-number f-16"></div>
                    </div>
                    <div className="col-2  pl-2 pt-3">
                        <div className="mobile-number f-16"></div>
                    </div>
                    <div className="col-2 text-center pl-2 pt-3 f-16 bold"></div>
                    <div className="col-2  pl-2 pt-3">
                        <div className="mobile-number f-16"><span className="text-success"></span></div>
                    </div>
                    <div className="col-1 text-right pt-2 repeat"></div>
                </div>
            </React.Fragment>
        );
    }
}