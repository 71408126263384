import React from 'react';

export default class LoadingBusDetail extends React.Component {
    render(){
        return(
            <div className="bus-block col-12">
                <div className="bus-items first">
                    <div className="row">
                        <div className="col-md-3 col-12">
                            <p className="bus-title bp3-skeleton">--</p>
                            <p className="bus-coach bp3-skeleton">--</p>
                        </div>
                        <div className="col-md-3 col-12">
                            <p className="bus-time bp3-skeleton">--<i className="material-icons font-13">arrow_forward</i>--</p>
                            <p className="bus-duration bp3-skeleton">--</p>
                        </div>
                        <div className="col-md-3 col-12">
                            <p className="bus-tprice bp3-skeleton"><span>--</span></p>
                            <p className="bus-tfrom bp3-skeleton">Starting from</p>
                        </div>
                        <div className="col-md-3 col-12">
                            <p className="bus-progress bp3-skeleton"></p>
                            <p className="bus-seat bp3-skeleton">23Seats available<br/>15Window</p>
                        </div>
                    </div>
                </div>
                <div className="bus-amenities">
                    <div className="row bp3-skeleton">
                        <div className="col-md-9 col-12">
                            <ul>
                                <li><span className="">Amenities</span></li>
                                <li><span className="">Boarding &amp; Dropping PointsRating</span></li>
                                <li className="after-last"><span className="">Cancellation Policy</span></li>
                            </ul>
                        </div>
                        <div className="col-md-3 col-12"><button className="bp3-button bp3-intent-primary bp3-fill bus-btnseat">View Seat</button></div>
                    </div>
                </div>
            </div>
        );
    }
}