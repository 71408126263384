import React, {Component} from 'react';
import PrivateRoute from './PrivateRoute';
import { Switch } from 'react-router-dom';
import {Notfound, Maintance} from "gg-react-utilities";
import config from '../Config';

import '../../node_modules/normalize.css/normalize.css';
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";

import "gg-react-utilities/src/css/style.css";
import "gg-react-utilities/src/css/gg-styles.css";

import Home from '../home';
import History from '../history';
import TripList from '../trip-list';
import Passenger from '../passenger';
import Payment from '../payment';

export default class Root extends Component{
    render(){

        if(config.IS_MAINTENANCE_MODE){
            return(
                <PrivateRoute path="*" component={Maintance} />
            );
        }

        return(
            <Switch>
                <PrivateRoute exact path="/" component={Home} />
                <PrivateRoute exact path="/order-history" component={History} />
                <PrivateRoute exact path="/trip-list" component={TripList} />
                <PrivateRoute exact path="/passenger" component={Passenger} />
                <PrivateRoute exact path='/payment/:status/:bookingid?' component={Payment}/>
                <PrivateRoute path="*" component={Notfound}/>
            </Switch>
        );
    }
}