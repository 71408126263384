import _ from 'lodash';

let initialState = {
    bookingHistoryList: [],
    ticketDetail:'',

    trip: null,
    boardingPoint:null,
    droppingPoint:null,
    selectedSeats:[],
    primaryPassenger:null,
    otherPassengers:null,
    initialBookingRes:null,
    coinData:[],
};

const Booking = (state = initialState, action) => {
    switch (action.type) {
        
        case 'BOOKING_HISTORY':
            return {...state, bookingHistoryList: action.sources}
        
        case 'TICKET_DETAIL':
            return {...state, ticketDetail: action.sources}

        case 'CHOOSE_TRIP':
            return {
                ...state,
                trip: action.trip,
                boardingPoint:null,
                droppingPoint:null,
                selectedSeats:[],
                primaryPassenger:null,
                otherPassengers:null
            }
        
        case 'SELECT_SEAT':
            return{
                ...state, 
                selectedSeats:[...state.selectedSeats,action.seat],
                primaryPassenger:null,
                otherPassengers:null,
            }
        
        case 'UNSELECT_SEAT':
            let selectedSeatss = _.reject(state.selectedSeats,function(seat){
                return seat.name === action.seat.name;
            });
            return {...state, selectedSeats:selectedSeatss}

        case 'RESET_SELECTED_SEATS':
            return {...state, selectedSeats:[]}

        case 'INITIAL_BOOKING_HISTORY':
            return {...state, initialBookingRes: action.sources}

        case 'COIN_DATA_ACTION':
            return {...state, coinData: action.sources}

        default:
            return state
    }
};
export default Booking;