import React from 'react';

export default class LoadingBusDetail extends React.Component {
    render(){
        return(
            <label className="bp3-control bp3-checkbox sb-chk-text bp3-skeleton">
                <input type="checkbox" /><span className="bp3-control-indicator"></span>
                ------
            </label>
        );
    }
}