import {combineReducers} from 'redux';
import SourceCity from './SourceCity';
import Trip from './Trip';
import Booking from './Booking';

const rootReducers = combineReducers({
    SourceCity,
    Trip,
    Booking
});

export default rootReducers;