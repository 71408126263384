import React from 'react';
import TopBar from "../core/components/TopBar";
import SearchBarContainer from "../home/container/SearchBarContainer";
import TripSearchContainer from './containers/TripSearchContainer';

export default class TripList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchParaObj:null,
            selectedSourceName:'',
            selectedDestinationName:'',
        }
    }

    componentDidMount(){
    
        let search = this.props.history.location.search.substring(1);
        let searchObj=JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g,'":"') + '"}', function(key, value) { return key===""?value:decodeURIComponent(value) });
    
        this.setState({
            isSearch:true,
            selectedDestinationName:searchObj.destination_name,
            selectedSourceName:searchObj.source_name,
            searchParaObj:searchObj
        })
    }
    
    onSearch(parameter){    
        this.setState({
            selectedDestinationName:parameter.destination_name,
            selectedSourceName:parameter.source_name,
            isSearch:true,
            searchParaObj:parameter
        })
    };

    render() {
        return (
            <React.Fragment>
                <div className="container">
                    <div className="bus-list">
                        <div className="row">
                            {
                                this.state.searchParaObj?
                                <TopBar history={this.props.history} title={this.state.selectedSourceName +' to '+ this.state.selectedDestinationName + ' Available Buses '}/>
                                :
                                <TopBar history={this.props.history} title={'searching...'}/>
                            }
                        </div>
                        
                        <SearchBarContainer isModifyPage="1" 
                            searchParam={this.state.searchParaObj}  
                            onSearch={(parameter)=>{
                            this.setState({
                                isSearch:false
                            },()=>{
                                this.onSearch(parameter)
                            })
                        }} />
                        
                        {
                            this.state.isSearch?
                                <TripSearchContainer searchParam={this.state.searchParaObj} history={this.props.history} />
                            :
                                ""
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }
}