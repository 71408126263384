import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import SeatContainer from '../containers/SeatContainer';
import SelectedSeatsContainer from '../containers/SelectedSeatsContainer';
import LoadingBusSeatLayout from '../components/LoadingBusSeatLayout';

export default class SeatLayout extends React.Component {

    constructor(props){
        super(props);
        this.state = {
        }
    }
    
    renderDeck(seats){
        
        return(
            Object.keys(seats).map((deck, index)=>{
                return(
                    <div key={index} className="seat-deck col-xl-5 col-lg-5 col-md-6 col-sm-5 col-12 mb-3 mr-2">
                        {(0===index) ? "LOWER" : "UPPER"}
                        <div className="row flex-row-reverse justify-content-center mt-4">
                            {this.renderRow(seats[deck])}
                        </div>
                    </div>
                );
            })
        );
    }

    renderRow(deck){

        let rows = Object.keys(deck);

        rows = rows.map(row => parseInt(row));

        let rowsx = _.range(_.min(rows),_.max(rows)+1);

        rowsx = rowsx.map((row)=>{
            
            if(deck.hasOwnProperty(row)){
                return deck[row];
            } else{
                return {}
            }
        });

        return(
            rowsx.map((row,index)=>{
                return(
                    <div key={index} className={classNames("seat-row align-self-md-end col-2",{"text-right":(Object.keys(row).length < 4)})}>
                        {this.renderColumn(row)}
                    </div>
                );
            })
        );
    }

    renderColumn(row){

        return(
            Object.keys(row).map((col, index)=>{
                return(
                    <div key={index} className={classNames('seat-col',
                        {
                            seater:(1==row[col].width && 1==row[col].length)
                        },
                        {
                            'sleeper-x':(2==row[col].length && 1==row[col].width)
                        },
                        {
                            'sleeper-y':(1==row[col].length && 2==row[col].width)
                        },
                        {
                            'unavailable':("false"===row[col].available)
                        },
                        {
                            'ladies-seat':("true"==row[col].ladiesSeat)
                        }
                    )}>
                    
                        {this.renderSeat(row[col])}
                    </div>
                );
            })
        );
    }

    renderSeat(col){       
        return(
            <SeatContainer details={col} viewOnly={this.props.viewOnly} />
        );
    }
    
    nextPage(route,response){
        this.props.history.push(route,{
          query:response
        });
    }

    render(){

        return(
            <div className="col-12">
				<div className="row">
					<div className="mb-4 text-left avability">
						<p className="d-inline mr-3"><span className="seat-legend available"></span>Available</p>
						<p className="d-inline mr-3"><span className="seat-legend ladies"></span>Ladies Seat</p>
						<p className="d-inline"><span className="seat-legend unavailable"></span>Unavailable</p>
					</div>
				</div>
				<div className="row">
					<div className="col-xl-8 col-lg-8 col-md-7 col-sm-12 col-12">
                        <div className="seat-layout mb-3 row justify-content-sm-center">
							{
								(null !== this.props.seats) ?
									this.renderDeck(this.props.seats)
								:
								<LoadingBusSeatLayout />
							}
						</div>
					</div>
					<SelectedSeatsContainer searchParam={this.props.searchParam} busDetail={this.props.busDetail} boardingTimes={this.props.boardingTimes} droppingTimes={this.props.droppingTimes}
						nextPage={(route, response)=>{
							this.nextPage(route, response)
						}}
					/>
				</div>
             </div>
        );
    }
}