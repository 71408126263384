import React from 'react';
import {Dialog, Classes} from "@blueprintjs/core";
import moment from 'moment';
import SeatLayout from './SeatLayout';
import Config from '../../Config';
import {ssoInstance} from 'gg-react-utilities';

export default class SeatLayoutDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            showSeatLayout: false,
            seatLayout: null,
            viewLayoutBtnState: 0
        }
    }

    closeModal3 = (props) => {
        this.setState({isOpen: false})
    }

    onHandleOpen = (tripid) => {
        this.setState({
            isOpen: true
        }, () => {
            ssoInstance
                .get(`${Config.API_URL}trips/${tripid}/seats`)
                .then(res => {
                    const layout = res.data.data.seatList;
                    this.setState({seatLayout: layout, showSeatLayout: true, viewLayoutBtnState: 0});
                })
        })
    }
    
    render() {
        return (
            <React.Fragment>
                <button className="bp3-button bp3-intent-primary bp3-fill bus-btnseat" onClick={() => this.onHandleOpen(this.props.busDetail.id)}>View Seats</button>
                <Dialog
                    isOpen={this.state.isOpen}
                    onClose={this.closeModal3}
                    title={this.props.busDetail.travels+" | "+this.props.busDetail.busType +" | "+moment()
                    .startOf('day')
                    .add(this.props.busDetail.departureTime, 'minutes')
                    .format('HH:mm') }
                    className="bp3-dialog-xlarge ticket-model">
                    <div className={Classes.DIALOG_BODY}>
                        <SeatLayout searchParam={this.props.searchParam} busDetail={this.props.busDetail} history={this.props.history} seats={this.state.seatLayout} viewOnly={true} boardingTimes={this.props.boardingTimes} droppingTimes={this.props.droppingTimes} />
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            {this.props.footer
                                ? this.props.footer
                                : ''
                            }
                        </div>
                    </div>
                </Dialog>
            </React.Fragment>
        )
    }
}