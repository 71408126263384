import React, {Component} from 'react';
import {Button, Intent, Position, Toaster} from "@blueprintjs/core";
import { withRouter } from 'react-router-dom';
import  Async  from 'react-select/async';
import {numberFormat} from './../../CommonFunction';

export const calcFare = (baseFare) => {
    baseFare = parseFloat(baseFare);
    return baseFare;
}

class SelectedSeats extends Component {

    constructor(props){
        super(props);
        this.state = {
            selectedBoardingPoint:'',
            selectedDroppingPoint:'',
            boardingPointList: [],
            droppingPointList: []
        }
    }

    componentDidMount() {
        
        let boardingTimes = this.getBoardingOptions(this.props);
        let droppingTimes = this.getDroppingOptions(this.props);

        this.setState({
            boardingPointList: boardingTimes,
            droppingPointList: droppingTimes
        });

        this.props.resetSelectSeats();
    }

    getBoardingOptions = (nextProps) => {
        let boardingOptions = [];
        if((Array.isArray(nextProps.boardingTimes))) {
            nextProps.boardingTimes.forEach((points)=>{
                boardingOptions.push({
                    value:points.bpId,
                    label:points.address ? points.address : points.landmark
                });
            });
        } else {
            boardingOptions.push({
                value:nextProps.boardingTimes.bpId,
                label:nextProps.boardingTimes.address ? nextProps.boardingTimes.address : nextProps.boardingTimes.landmark
            }); 
        }
        return boardingOptions;
    }

    getDroppingOptions = (nextProps) => {
        let droppingOptions = [];
        if((Array.isArray(nextProps.droppingTimes))) {
            nextProps.droppingTimes.forEach((points)=>{
                droppingOptions.push({
                    value:points.bpId,
                    label:points.address ? points.address : points.landmark 
                });
            });
        } else {
            if(nextProps.droppingTimes !== undefined) {
                droppingOptions.push({
                    value:nextProps.droppingTimes.bpId,
                    label:nextProps.droppingTimes.address ? nextProps.droppingTimes.address : nextProps.droppingTimes.landmark 
                }); 
            }
        }
        return droppingOptions;
    }

    loadBoardingPoints = (inputValue, callback) => {
        setTimeout(() => {
          callback(this.filterBoardingPoints(inputValue));
        }, 1000);
    };

    filterBoardingPoints = (inputSource: string) => {
        if (inputSource) {
            return this.state.boardingPointList.filter(i =>
                i.label.toLowerCase().includes(inputSource.toLowerCase())
            );
        }
        return this.state.boardingPointList;
    };

    handleBoardingPointSelect = (option) => {
        if(option!=null){
            this.setState({ 
                selectedBoardingPoint:option
            });
        }
    };

    loadDroppingPoints = (inputValue, callback) => {
        setTimeout(() => {
          callback(this.filterDroppingPoints(inputValue));
        }, 1000);
    };

    filterDroppingPoints = (inputSource: string) => {
        if (inputSource) {
            return this.state.droppingPointList.filter(i =>
                i.label.toLowerCase().includes(inputSource.toLowerCase())
            );
        }
        return this.state.droppingPointList;
    };

    handleDroppingPointSelect = (option) => {
        if(option!=null){
            this.setState({ 
                selectedDroppingPoint:option
            });
        }
    };

    getPassengerPage = () => {

        if(this.props.selectedSeats.length > 5) {
            Toaster.create({position: Position.TOP}).show({message:'Maximum 5 seat, You can book in single ticket.',intent: Intent.DANGER});
			return false;
        }

        if(this.state.selectedBoardingPoint.label===undefined || this.state.selectedBoardingPoint.label==='') {
            Toaster.create({position: Position.TOP}).show({message:'Please select boarding point.',intent: Intent.DANGER});
			return false;
        }
        
        if(this.state.selectedDroppingPoint.label===undefined || this.state.selectedDroppingPoint.label==='') {
			Toaster.create({position: Position.TOP}).show({message:'Please select dropping point.',intent: Intent.DANGER});
			return false;
        }

        let params = {
            tripDetail : this.props.searchParam,
            busDetail : this.props.busDetail,
            selectedSeat : this.props.selectedSeats,
            selectedBoardingPoint : this.state.selectedBoardingPoint,
            selectedDroppingPoint : this.state.selectedDroppingPoint
        }
        this.props.nextPage('/passenger', params);
    }

    redirectHome = () => {
        this.props.history.push('/');
    }

    render(){
        return(
            <div className="col-xl-4 col-lg-4 col-md-5 col-sm-12 col-12 p-s-0">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
                        <Async
                        multi={false}
                        isLoading={true}
                        onChange={this.handleBoardingPointSelect}
                        Key="id"
                        loadOptions={this.loadBoardingPoints}
                        placeholder={'Boarding Point'}
                        defaultOptions
                        backspaceRemoves={true}
                    />
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
                        <Async
                        multi={false}
                        isLoading={true}
                        onChange={this.handleDroppingPointSelect}
                        Key="id"
                        loadOptions={this.loadDroppingPoints}
                        placeholder={'Dropping Point'}
                        defaultOptions
                        backspaceRemoves={true}
                    />
                    </div>
                </div>

                {
                (this.props.selectedSeats.length === 0) ?
                    <div className="border p-2">
                        <p>You have not selected any seat yet. Select a seat by clicking on the seat icon.</p>
                    </div>
                
                :
                <div className="mt-3">
                    <h3>No of Selected Seat: {this.props.selectedSeats.length > 0 ? this.props.selectedSeats.length : '0'}</h3>
                    <div className="row seat-price-block">
                        {
                            this.props.selectedSeats.map((seat, index)=>{
                                return <div className="col-xl-12" key={index}>
                                        <div className="row">
                                            <div className="col-xl-6 col-sm-6 col-6 text-left">
                                                <p>Seat No:</p>
                                            </div>
                                            <div className="col-xl-6 col-sm-6 col-6 text-right">
                                                <p>{seat.name}</p>
                                            </div>
                                            <div className="col-xl-6 col-sm-6 col-6 text-left">
                                                <p>Basic fare</p>
                                            </div>
                                            <div className="col-xl-6 col-sm-6 col-6 text-right">
                                                <p>{numberFormat(seat.fare)}</p>
                                            </div>
                                            <div className="col-md-12 col-12">
                                                <div className="col-md-12 col-12 border-top"></div>	
                                            </div>
                                        </div>
                                    </div>
                            
                            })          
                        }
                        <div className="col-xl-6 col-sm-6 col-6 text-left mt-2">
                            <p><strong>Total Fare</strong></p>
                        </div>
                        <div className="col-xl-6 col-sm-6 col-6 text-right mt-2">
                            <p><strong>{ numberFormat(
                                calcFare(this.props.selectedSeats.reduce(function(totalFare, seat){ return totalFare + parseInt(seat.fare)},0)))
                            }</strong>
                            </p>
                        </div>
                    </div>              

                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-5 d-flex justify-content-end">
                        <div className="row">
                            <Button intent={Intent.DANGER} onClick={this.redirectHome} className="rounded mr-4">
                                Cancel
                            </Button>

                            <Button intent={Intent.PRIMARY}className="rounded" onClick={this.getPassengerPage}>
                                Proceed
                            </Button>
                        </div>
                    </div>
                </div>
                }
            </div>
        )
    }
}

export default withRouter(SelectedSeats);