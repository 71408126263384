import React from 'react';
import BusDetail from "./BusDetail";
import LoadingBusDetail from "./LoadingBusDetail";

export default class BusSearchList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loadingData: true,
            isTripDataFiltered:false,
            tripList:[]
        }
    }

    componentWillReceiveProps(nextProps) {

        if(nextProps.isTripDataFiltered) {
            this.setState({
                loadingData: false,
                isTripDataFiltered:true,
                tripList: nextProps.tripList
            });
        } else {
            setTimeout(() => {
                this.setState({
                    loadingData: false,
                    isTripDataFiltered:false,
                    tripList: nextProps.tripList
                });
            }, 1000);
        }
    }

    render(){
        return(
            <React.Fragment>
            {
                this.state.loadingData === true ?
                    <div className="row">
                        <LoadingBusDetail />
                    </div>
                :
                    this.state.tripList.length > 0 ?
                
                        this.state.tripList.map((bus, key) => {                            
                            return <BusDetail searchParam={this.props.searchParam} history={this.props.history} data={bus} key={key} index={key} onShowSeatLayout={(layoutResponse)=>{
                            }} />
                        })
                    :

                        this.state.isTripDataFiltered ? 

                            this.state.tripList.length > 0 ? 
                                <div className="row">
                                    <LoadingBusDetail />
                                </div>
                                :
                                    <div className="no-record"> No Bus Found.</div>

                        :

                            this.props.tripListMsg !== "No bus found" ? 
                                <div className="row">
                                    <LoadingBusDetail />
                                </div>
                            :
                                <div className="no-record"> No Bus Found.</div>
            }   
            </React.Fragment>
        );
    }
}