import React from 'react';
import KeyboardArrowLeftRounded from '@material-ui/icons/KeyboardArrowLeftRounded';

export default class TopBar extends React.Component {
    render(){
        return(
            <div className="top-bar">
                <span className="back-icon"><KeyboardArrowLeftRounded 
                    onClick={() => {
                       this.props.history.goBack();
                    }} /></span>
                <h1 className="title m-0">{this.props.title}</h1>
            </div>
        );
    }
}