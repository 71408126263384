import React from 'react';
import {InputGroup, Button, Intent, Toaster,Toaster as toast, Position} from "@blueprintjs/core";
import { Auth } from 'gg-react-utilities';
import moment from 'moment';
import Select from 'react-select';
import LoadingPassengerForm from "./LoadingPassengerForm";
import LoadingPaymentDetail from "./LoadingPaymentDetail";
import RedeemCoinContainer from "./../containers/RedeemCoinContainer";
import _ from 'lodash';
import {numberFormat} from './../../CommonFunction';

const titleOptions = [
	{ value: 'Mr.', label: 'Mr.' },
	{ value: 'Mrs.', label: 'Mrs.' },
	{ value: 'Ms.', label: 'Ms.' }
]

const idOptions = [
	{ value: 'AADHAR', label: 'Aadhar Card' },
	{ value: 'DRIVING_LICENCE', label: 'Driving Licence' },
	{ value: 'VOTER_CARD', label: 'Election Card' },
	{ value: 'PAN_CARD', label: 'Pan Card' }
]

const genderOptions = [
	{ value: 'MALE', label: 'Male' },
	{ value: 'FEMALE', label: 'Female' }
]

export const calcBasicFare = (baseFare) => {
    baseFare = parseFloat(baseFare);
    return baseFare.toFixed(2);
}

export const calcPaymentGatewayCharge = (baseFare) => {
    baseFare = parseFloat(baseFare);
    return ( baseFare * 0.025 ).toFixed(2);
}

export const calcConvinienceCharge = (baseFare) => {
    // baseFare = parseFloat(baseFare);
	// return ( baseFare * 0.05 ).toFixed(2);
	return 0;
}

export const calcSubTotal = (baseFare) => {
    baseFare = parseFloat(baseFare);
    let subTotal = baseFare + ( baseFare * 0.025 );
    return subTotal.toFixed(2);
}

export const maxGGcoins = (baseFare) => {
    baseFare = parseFloat(baseFare) * 0.05;
    return Math.floor(baseFare);
}

export const calcTotalAmount = (baseFare) => {
	baseFare = parseFloat(baseFare);
	let total = baseFare + ( baseFare * 0.025 );
	return total.toFixed(2);
}

export default class PassengerForm extends React.Component {

	constructor(props){
        super(props);
        this.state = {
			loadingData: true,			
			tripDetail:this.props.tripDetail,
            busDetail:this.props.busDetail,
            selectedSeats:this.props.selectedSeats,
            selectedBoardingPoint:this.props.selectedBoardingPoint,
            selectedDroppingPoint:this.props.selectedDroppingPoint,
			
			primaryPassengers:null,
			otherPassengers:[],
			ggCoins:0,
			applyGgCoins:0,
			isTermApprove:true,
			error_status:true,
			loadingBtnStatus: false,
		}
	}
	
	componentWillReceiveProps(nextProps){
		this.setPassangerDetails(nextProps.selectedSeats);
		this.setState({
			loadingData: false,
			tripDetail:nextProps.tripDetail,
			busDetail:nextProps.busDetail,
			selectedSeats:nextProps.selectedSeats,
			selectedBoardingPoint:nextProps.selectedBoardingPoint,
			selectedDroppingPoint:nextProps.selectedDroppingPoint
		});
	}

	setPassangerDetails=(selectedSeats)=>{
		
		if(selectedSeats){
			let primaryPassangerInfo = {};
				primaryPassangerInfo.title = "Mr.";
				primaryPassangerInfo.name = "";
				primaryPassangerInfo.age = "";
				primaryPassangerInfo.email = "";
				primaryPassangerInfo.mobile = "";
				primaryPassangerInfo.id_type = "";
				primaryPassangerInfo.id_number = "";
				primaryPassangerInfo.gender = "";
				primaryPassangerInfo.seat_name = (selectedSeats[0].name) ? selectedSeats[0].name : '';
				primaryPassangerInfo.error = {
					title : false,
					name : false,
					age : false,
					email : false,
					mobile : false,
					id_type : false,
					id_number : false,
					gender : false
				}
				this.setState({
					primaryPassengers:primaryPassangerInfo
				});	

			
			if(selectedSeats.length > 1) {
				let otherPassengersInfo = [];
				for (let i = 1; i <= selectedSeats.length-1; i++) {
					let obj = {};
					obj.title = "Mr.";
					obj.name = "";
					obj.age = "";
					obj.seat_name = (selectedSeats[i].name) ? selectedSeats[i].name : '';;
					obj.error={
						title : false,
						name : true,
						age : true
					};
					otherPassengersInfo.push(obj);
				}
				this.setState({
					otherPassengers:otherPassengersInfo
				});
			}
        }
	}

	handlePrimaryPassengerChange = (value, fieldname)=>{
		
		let obj = this.state.primaryPassengers;
		if(fieldname==='title'){
			obj.title =value.value;
		}
		
		if(fieldname==='name'){
			obj.name=value;
		}

		if(fieldname==='age'){
			obj.age=value;
		} 

		if(fieldname==='email'){
			obj.email=value;
		} 

		if(fieldname==='mobile'){
			obj.mobile=value;
		}

		if(fieldname==='id_type'){
			obj.id_type=value.value;
		} 

		if(fieldname==='id_number'){
			obj.id_number=value;
		}

		if(fieldname==='gender'){
			obj.gender=value.value;
		}
		
        this.setState({
            primaryPassengers:obj
        });
	}

	handleOtherPassengerChange = (value,index,fieldname)=>{

        this.state.otherPassengers.map((obj,i)=>{
            if(index===i && fieldname==='title'){
                obj.title=value.value;
            }
            if(index===i && fieldname==='name'){
                obj.name=value;
            }
            if(index===i && fieldname==='age'){
                obj.age=value;
            }
            return obj;
        });
        this.setState({
            otherPassengers:this.state.otherPassengers
        });
        this.validate();
    }

    validate=()=>{
		let error_status=false;
        this.state.otherPassengers.map((obj,i)=>{
            if(obj.name===''){
                error_status=true;
                obj.error.name=true;
            }else{
                obj.error.name=false;
            }
            if(obj.age===''){
                error_status=true;
                obj.error.age=true;
            }else{
                obj.error.age=false;
            }
          	return obj;
        });
        this.setState({
            error_status:error_status,
            otherPassengers:this.state.otherPassengers
        });
	}
	
	 placeOrder = async () => {

		let filter = _.filter(this.state.busDetail['boardingTimes'],(obj,key)=>{
			if(obj.bpId == this.state.selectedBoardingPoint.value){
				return obj;
			}
		})

		let boardingObj={};
		filter.map((r,i)=>{
			boardingObj = r;
		})

		if (!/^[A-Za-z\s]+$/.test(this.state.primaryPassengers.name)) {
			toast.create({position: Position.TOP}).show({message:'Please enter valid primary passenger name.',intent: Intent.DANGER});
			return false;
		}

		if (!/^[\d]+$/.test(this.state.primaryPassengers.age)) {
			toast.create({position: Position.TOP}).show({message:'Please enter valid primary passenger age.',intent: Intent.DANGER});
			return false;
		} 

		if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.primaryPassengers.email)) {
			toast.create({position: Position.TOP}).show({message:'Please enter valid primary passenger email.',intent: Intent.DANGER});
			return false;
		}

		if (!/^\d{10}$/.test(this.state.primaryPassengers.mobile)) {
			toast.create({position: Position.TOP}).show({message:'Please enter valid primary passenger mobile no.',intent: Intent.DANGER});
			return false;
		}

		if(this.state.primaryPassengers.id_type==='') {
			toast.create({position: Position.TOP}).show({message:'Please select id.',intent: Intent.DANGER});
			return false;
		}

		if (!/^[A-Za-z\d\s]+$/.test(this.state.primaryPassengers.id_number)) {
			toast.create({position: Position.TOP}).show({message:'Please enter valid id number.',intent: Intent.DANGER});
			return false;
		}

		if(this.state.primaryPassengers.gender==='') {
			toast.create({position: Position.TOP}).show({message:'Please select gender.',intent: Intent.DANGER});
			return false;
		}
		
		if(this.state.selectedSeats.length > 1 && this.state.error_status){
			toast.create({position: Position.TOP}).show({message:'Please fill other passenger data.',intent: Intent.DANGER});
			return false;
		}

		if(!this.state.isTermApprove){
			toast.create({position: Position.TOP}).show({message:'Please Accept Terms & Conditions.',intent: Intent.DANGER});
			return false;
		}else{

			let users = await Auth.user();
			let bookingRequest = {
				source: this.state.tripDetail.source_id,
				source_name:this.state.tripDetail.source_name,
				destination:this.state.tripDetail.destination_id,
				destination_name:this.state.tripDetail.destination_name,
				coins:this.state.applyGgCoins,
				trip_id:this.state.busDetail.id,
				doj:moment(this.state.busDetail.doj).format('YYYY-MM-DD'),
				travel_name:this.state.busDetail.travels,
				travel_type:this.state.busDetail.busType,
				boarding_point_name:this.state.selectedBoardingPoint.label,
				dropping_point_name:this.state.selectedDroppingPoint.label,
				departure_time: moment().startOf('day').add(boardingObj.time, 'minutes').format('HH:mm'),
				reporting_time:moment().startOf('day').add(boardingObj.time, 'minutes').subtract(15, "minutes").format('HH:mm'),
				contact:boardingObj.contactNumber,
				cancellation_policy:this.state.busDetail.cancellationPolicy,

				customer_user_id:(await Auth.isLoggedIn() ? users.user_id : ''),
				customer_username:(await Auth.isLoggedIn() ? users.username : ''),
				boarding_point_id:this.state.selectedBoardingPoint.value,
				dropping_point_id:this.state.selectedDroppingPoint.value,
				primary_seat:this.state.primaryPassengers,
				other_seats:this.state.otherPassengers
			}
		
			this.setState({loadingBtnStatus: true});
			
			this.props.initialBooking(bookingRequest).then((res) => {
				
				this.setState({loadingBtnStatus: false});
				
				const resdata = res.data;
				
				if (resdata.status === true) {
					
					const PayUBiz = resdata.data.pg;
					let form = document.createElement("form");
					form.setAttribute("method", "POST");
					form.setAttribute("action", PayUBiz.action_url);

					for (let key in PayUBiz) {
						let hiddenField = document.createElement("input");
						hiddenField.setAttribute("type", "hidden");
						hiddenField.setAttribute("name", key);
						hiddenField.setAttribute("value", PayUBiz[key]);
						form.appendChild(hiddenField);
					}
					document.body.appendChild(form);
					form.submit();
				} else {
					Toaster.create({position: Position.TOP,}).show({message: resdata.message, intent: Intent.DANGER});
				}
	
			}).catch((error) => {
				this.setState({loadingBtnStatus: false});
				const sources = error.response
				if (sources.data.message === "Validation Error") {
					sources.data.error.map((res) => {
						Toaster.create({position: Position.TOP}).show({message:res,intent: Intent.DANGER});
						return res
					})
				} else {
					Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
				}
			});
		}
	}

	applyCoin(applyCoin) {
		this.setState({
			applyGgCoins:applyCoin	
		})
	}

    render(){
		return(
			<div className="row">
				<div className="col-xl-8 col-lg-8 col-md-8 col-sm-7 col-12">
					{
						this.state.loadingData === true ?
							<div className="row">
								<LoadingPassengerForm />
							</div>
					:						
						<div>
							<div className="booking-form pb-4">
								<div className="col-md-12 col-12">
									<div className="row">
										<div className="col-md-12">
											<div className="row">
												<div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-7 pr-s-1">
													<h2 className="bp3-text-xlarge heading">Primary Passenger</h2>
												</div>
												<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-5 pl-s-1">
													<p className="seat-allocate">Seat : {this.state.primaryPassengers.seat_name}</p>
												</div>
											</div>
										</div>
										
										<div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mt-4">
											<Select 
												clearable={false}
												options={titleOptions}
												placeholder="Title"
												name="title"
												onChange={(value)=>{this.handlePrimaryPassengerChange(value, 'title')}}
											/>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-4">
											<InputGroup 
												className={this.state.primaryPassengers.error.name===true?'form-control is-invalid':'form-control'} 
												type='text' placeholder="Passenger Name" name='name' 
												value={this.state.primaryPassengers.name?this.state.primaryPassengers.name:''} 
												onChange={(event)=>{
													this.handlePrimaryPassengerChange(event.target.value, 'name')
												}}
											/>
										</div>
										
										<div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 mt-4">
											<InputGroup 
												className={this.state.primaryPassengers.error.age===true?'form-control is-invalid':'form-control'} 
												type='text' placeholder="Age" name='age' 
												value={this.state.primaryPassengers.age?this.state.primaryPassengers.age:''} 
												onChange={(event)=>{
													this.handlePrimaryPassengerChange(event.target.value, 'age')
												}}
											/>
										</div>
									
										<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-4">
											<InputGroup 
												className={this.state.primaryPassengers.error.email===true?'form-control is-invalid':'form-control'} 
												type='text' placeholder="Email" name='email' 
												value={this.state.primaryPassengers.email?this.state.primaryPassengers.email:''} 
												onChange={(event)=>{
													this.handlePrimaryPassengerChange(event.target.value, 'email')
												}}
											/>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-4">
											<InputGroup 
												className={this.state.primaryPassengers.error.email===true?'form-control is-invalid':'form-control'} 
												type='text' placeholder="Mobile" name='mobile' 
												value={this.state.primaryPassengers.mobile?this.state.primaryPassengers.mobile:''} 
												onChange={(event)=>{
													this.handlePrimaryPassengerChange(event.target.value, 'mobile')
												}}
											/>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-4">
											<Select 
												clearable={false}
												options={idOptions}
												placeholder="Select ID"
												name="id_type"
												onChange={(value)=>{this.handlePrimaryPassengerChange(value, 'id_type')}}
											/>
										</div>
	
										<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-4">
											<InputGroup 
												className={this.state.primaryPassengers.error.email===true?'form-control is-invalid':'form-control'} 
												type='text' placeholder="ID Number" name='id_number' 
												value={this.state.primaryPassengers.id_number?this.state.primaryPassengers.id_number:''} 
												onChange={(event)=>{
													this.handlePrimaryPassengerChange(event.target.value, 'id_number')
												}}
											/>
										</div>

										<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-4">
											<Select 
												clearable={false}
												options={genderOptions}
												placeholder="Gender"
												name="gender"
												onChange={(value)=>{this.handlePrimaryPassengerChange(value, 'gender')}}
											/>
										</div>
									</div>
								</div>
							</div>
							{
								this.state.otherPassengers.map((seat, key) => {
								
									return <div className="booking-form pb-4 border-top-0" key={key} index={key}>
											<div className="col-md-12 col-12">
												<div className="row">
													<div className="col-md-12">
														<div className="row">
															<div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-7 pr-s-1">
																<h2 className="bp3-text-xlarge heading">Passenger {(key+2)}</h2>
															</div>
															<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-5 pl-s-1">
																<p className="seat-allocate">Seat : {this.state.otherPassengers[key].seat_name}</p>
															</div>
														</div>
													</div>

													<div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mt-4">
														<Select 
															clearable={false}
															options={titleOptions}
															placeholder="Title"
															name="title"
															onChange={(value)=>{this.handleOtherPassengerChange(value,key,'title')}}
														/>
													</div>
													
													<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-4">
														<InputGroup className={seat.error.name===true?'form-control is-invalid':'form-control'} 
															type='text' placeholder="Passenger Name" name='name' 
															value={seat.name?seat.name:''} 
															onChange={(event)=>{
																this.handleOtherPassengerChange(event.target.value,key,'name')
															}}
														/>
													</div>
													
													<div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 mt-4">
														<InputGroup className={seat.error.name===true?'form-control is-invalid':'form-control'} 
															type='text' placeholder="Age" name='age' 
															value={seat.age?seat.age:''} 
															onChange={(event)=>{
																this.handleOtherPassengerChange(event.target.value,key,'age')
															}}
														/>
													</div>
												</div>
											</div>
										</div>
								})
							}

							{/* <div className="col-md-12 col-12 mt-4 text-left">
								<div className="row">
									<Checkbox 
										className="sb-chk-text"
										checked={this.state.isTermApprove}
										onChange={() => { 
											this.setState(prevState => ({
												isTermApprove: !prevState.isTermApprove
											}));
										}}
										>I agree to all the <span className="text-primary">Terms and conditions.</span></Checkbox>
								</div>
							</div> */}
						</div>
					}
				</div>

				<div className="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-12 pl-sm-0 pt-s-4">
					{
						this.state.loadingData === true ?
						<LoadingPaymentDetail />
						:
							<div className="payment-detail py-2">
								<div className="col-md-12 col-12 d-flex">
									<h1 className="bp3-text-xlarge heading pt-xl-2 pt-lg-2 pt-md-2 pt-sm-0"> Payable amount</h1>
								</div>

								<div className="col-md-12 col-12 d-flex">
									<div className="col-md-8 payment-label">Basic fare</div>
									<div className="col-md-4 payment-value">
									{ 
										numberFormat(calcBasicFare(this.state.selectedSeats.reduce(function(totalFare, seat){ return totalFare + parseInt(seat.fare)},0)))
									}
									</div>
								</div>

								<div className="col-md-12 col-12 d-flex">
									<div className="col-md-8 payment-label">Payment gateway charge (2.5%)</div>
									<div className="col-md-4 payment-value">
									{
										numberFormat(calcPaymentGatewayCharge(this.state.selectedSeats.reduce(function(totalFare, seat){ return totalFare + parseInt(seat.fare)},0)))
									} 
									</div>
								</div>

								{/* <div className="col-md-12 col-12 d-flex">
									<div className="col-md-8 payment-label">Convenience charge (5%)</div>
									<div className="col-md-4 payment-value">
									{
										numberFormat(calcConvinienceCharge(this.state.selectedSeats.reduce(function(totalFare, seat){ return totalFare + parseInt(seat.fare)},0)))
									}
									</div>
								</div> */}
								
								<div className="col-md-12 col-12 d-flex">
									<div className="col-md-8 payment-label">Sub Total</div>
									<div className="col-md-4 payment-value">
									{
										numberFormat(calcSubTotal(this.state.selectedSeats.reduce(function(totalFare, seat){ return totalFare + parseInt(seat.fare)},0)))
									}
									</div>
								</div>
								
								{/* <div className="col-md-12 col-12 d-flex">
									<div className="col-md-12 col-12 border-top"></div>	
								</div>

								<RedeemCoinContainer 
										selectedSeats={this.state.selectedSeats} 
										maxCoinLimit={maxGGcoins(this.state.selectedSeats.reduce(function(totalFare, seat){ return totalFare + parseInt(seat.fare)},0))} 
										applyCoin={(coin)=>{
											this.applyCoin(coin)
										}}
										/> */}
								
								<div className="col-md-12 col-12 d-flex">
									<div className="col-md-12 col-12 border-top"></div>	
								</div>

								<div className="col-md-12 col-12 d-flex">
									<div className="col-md-6 payment-label"><span>Grand Total</span></div>
									<div className="col-md-6 payment-value">
										<span>
										{ 
											numberFormat(calcTotalAmount(this.state.selectedSeats.reduce(function(totalFare, seat){ return totalFare + parseInt(seat.fare)},0)) - this.state.applyGgCoins)
										}    
										</span>
									</div>
								</div>

								<div className="col-md-12 col-12 mt-3">
									<Button className="bp3-button pull-right bus-payment" loading={this.state.loadingBtnStatus === false ? false : true} onClick={this.placeOrder} intent={Intent.PRIMARY}>Proceed to Pay</Button>
								</div><br/>

								<div className="bp3-card bp3-elevation-0 col-12" style={{padding:'15px',marginTop:'45px'}}>
									<p>*Attention!</p><span>Do you want to make transaction with 0% payment gateway charge? contact our UPOS agent.</span>
								</div>
							</div>
					}
				</div>
			</div>
        );
    }
}