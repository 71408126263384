import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import busStore from '../reducers';

const persistConfig = {
    key: 'root',
    storage: storage,
    blacklist:['busApp','agent','form','megaBrandApp','rechargeApp','storeApp','flightApp','bswApp']
  }

const persistedReducer = persistReducer(persistConfig, busStore);

export default () => {
    let store = createStore(
        persistedReducer,
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
    let persistor = persistStore(store)
    return { store, persistor }
  }