import React from 'react';
import moment from 'moment';
import {Collapse} from "@blueprintjs/core";
import SeatLayoutDialog from './seatLayoutDialog';
import {numberFormat} from './../../CommonFunction';

export default class BusDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpenAmenities: false,
            isOpenStopList: false,
            isOpenPolicy: false
        };
    }

    showAmenities = () => {
        this.setState({
            isOpenStopList: false,
            isOpenPolicy: false,
            isOpenAmenities: !this.state.isOpenAmenities
        });
    }

    showStop = () => {
        this.setState({
            isOpenAmenities: false,
            isOpenPolicy: false,
            isOpenStopList: !this.state.isOpenStopList
        });
    }

    showPolicy = () => {
        this.setState({
            isOpenAmenities: false,
            isOpenStopList: false,
            isOpenPolicy: !this.state.isOpenPolicy
        });
    }

    render() {
        return (
            <div>
                <div className="bus-block">
                    <div
                        className={"bus-items " + (this.props.index === 0
                        ? "first"
                        : "")}>
                        <div className="row">
                            <div className="col-md-3 col-sm-6 col-6 p-md-2 pr-s-0">
                                {/*<p><i className="material-icons md-10">directions_bus</i></p>*/}
                                <p className="bus-title">{this.props.data.travels} {this.props.data.rtc == "true"  ? " - "+this.props.data.busServiceId : ''}</p>
                                <p className="bus-coach">{this.props.data.busType}</p>
                            </div>
                            <div className="col-sm-4 col-md-3 col-sm-6 col-6 p-sm-2">
                                <p className="bus-time">{moment()
                                        .startOf('day')
                                        .add(this.props.data.departureTime, 'minutes')
                                        .format('HH:mm')}
                                    <i className="material-icons font-13 pl-2 pr-2">arrow_forward</i>
                                    {moment()
                                        .startOf('day')
                                        .add(this.props.data.arrivalTime, 'minutes')
                                        .format('HH:mm')}</p>
                                <p className="bus-duration">{moment()
                                        .startOf('day')
                                        .add(this.props.data.arrivalTime - this.props.data.departureTime, 'minutes')
                                        .format('HH [Hours] mm [Minutes]')}</p>
                            </div>
                            <div className="col-sm-4 col-md-3 col-sm-6 col-6 p-sm-2">
                                <p className="bus-tprice">
                                    {('object' === (typeof this.props.data.fares))
                                        ? Array.prototype.map.call(this.props.data.fares, (fare, index) => {
                                            return <span key={fare}>{ numberFormat(fare)} {(index === (this.props.data.fares.length - 1))
                                                    ? ''
                                                    : '/'}</span>
                                        })
                                        : <span>{ numberFormat(this.props.data.fares)}</span>
                                    }
                                </p>
                                <p className="bus-tfrom">Starting from</p>
                            </div>
                            <div className="col-sm-4 col-md-3 col-sm-6 col-6 p-sm-2">
                                <div className="bus-progress">
									<div className="bp3-progress-bar bp3-intent-success bp3-no-stripes mb-3">
										<div className="bp3-progress-meter" style={{width: '50%'}}></div>
									</div>
								</div>
                                <p className="bus-seat">{this.props.data.availableSeats} Seats available
                                    <br/>{this.props.data.avlWindowSeats} Window</p>
                            </div>
                        </div>
                    </div>

                    <div className="bus-amenities">
                        <div className="row">
                            <div className="col-md-9 col-12">
                                <ul>
                                    <li>
                                        <span
                                            className={this.state.isOpenAmenities
                                            ? "text-primary"
                                            : ""}
                                            onClick={this.showAmenities}>Amenities</span>
                                    </li>
                                    <li>
                                        <span
                                            className={this.state.isOpenStopList
                                            ? "text-primary"
                                            : ""}
                                            onClick={this.showStop}>Boarding & Dropping Points</span>
                                    </li>
                                    <li className="after-last">
                                        <span
                                            className={this.state.isOpenPolicy
                                            ? "text-primary"
                                            : ""}
                                            onClick={this.showPolicy}>Cancellation Policy</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-3 col-12">
                                <SeatLayoutDialog searchParam={this.props.searchParam} history={this.props.history} busDetail={this.props.data} boardingTimes={this.props.data.boardingTimes} droppingTimes={this.props.data.droppingTimes} />
                            </div>
                        </div>

                        <Collapse isOpen={this.state.isOpenAmenities}>
                            <div className="row">
									<div className="col-md-2 col-12 bg-white pb-0 pt-0 border-0 hidden-xs hidden-sm visible-xl visible-lg visible-md">&nbsp;</div>
									<div className="col-md-10 col-12 bus-amenities-detail-row amenities-section border-left">
										<div className="desc-column">
										{
                                            ('true'===this.props.data.mTicketEnabled) ?
                                                <div className="amenities-desc">M-Ticket supported.</div>
                                            : 
                                                <div className="amenities-desc">M-Ticket not supported.</div>
                                        }     
										</div>
									</div>
								</div>
                        </Collapse>
                        
                        <Collapse isOpen={this.state.isOpenStopList}>
                            <div className="row">
								<div className="col-md-2 col-12 bg-white pb-0 pt-0 border-0 hidden-xs hidden-sm visible-xl visible-lg visible-md">&nbsp;</div>
								<div className="col-md-10 col-12 d-flex bus-amenities-detail-row board-drop-section border-left">
									<div className="desc-column d-flex">

										<div className="col-md-6 col-12">
											<div className="title">Boarding Point Detail</div>
											{(Array.isArray(this.props.data.boardingTimes))
                                                ? this.props.data.boardingTimes.map((bt, index) => {
                                                        return <div className="desc d-flex" key={index}>
                                                                <div className="time mr-5">{moment()
                                                                    .startOf('day')
                                                                    .add(bt.time, 'minutes')
                                                                    .format('HH:mm')}
                                                                </div>
                                                                <div className="stand-name">{bt.address ? bt.address : bt.landmark}</div>
                                                            </div>
                                                })
                                                :

                                                <div className="desc d-flex">
                                                    <div className="time mr-5">
                                                    {
                                                        ( this.props.data.boardingTimes !== undefined) ?
                                                            moment().startOf('day').add(this.props.data.boardingTimes.time, 'minutes').format('HH:mm')
                                                        : ''
                                                    }
                                                    </div>
                                                    <div className="stand-name">{
                                                        ( this.props.data.boardingTimes !== undefined) ?
                                                            this.props.data.boardingTimes.address ? this.props.data.boardingTimes.address : this.props.data.boardingTimes.landmark 
                                                        : ''
                                                    }</div>
                                                </div>
                                            }
										</div>

										<div className="col-md-6 col-12">
											<div className="title">Droping Point Detail</div>
											{(Array.isArray(this.props.data.droppingTimes))
                                            ? this.props.data.droppingTimes.map((bt, index) => {
                                                return <div className="desc d-flex" key={index}>
                                                    <div className="time mr-5">{moment()
                                                            .startOf('day')
                                                            .add(bt.time, 'minutes')
                                                            .format('HH:mm')}</div>
                                                    <div className="stand-name">{bt.address ? bt.address : bt.landmark}</div>
                                                </div>
                                            })
                                            : <div className="desc d-flex">
                                                <div className="time mr-5">
                                                {
                                                    ( this.props.data.droppingTimes !== undefined) ?
                                                        moment().startOf('day').add(this.props.data.droppingTimes.time, 'minutes').format('HH:mm')
                                                    : ''
                                                }
                                                </div>
                                                <div className="stand-name">{
                                                    ( this.props.data.droppingTimes !== undefined) ?
                                                        this.props.data.droppingTimes.address ? this.props.data.droppingTimes.address : this.props.data.droppingTimes.landmark
                                                    : ''
                                                }</div>
                                            </div>
                                        }
										</div>
									</div>
								</div>
							</div>
                        </Collapse>

                        <Collapse isOpen={this.state.isOpenPolicy}>
							<div className="row">
								<div className="col-md-2 col-12 bg-white pb-0 pt-0 border-0 hidden-xs hidden-sm visible-xl visible-lg visible-md">&nbsp;</div>
								<div className="col-md-10 col-12 bus-amenities-detail-row cancel-note-section border-left">
									<div className="desc-column">
										{
                                            this.props.data.cancellationPolicy.split(";").map((item,key)=>{
                                                item = item.split(":");
                                                if(0==item[0] && item[2] !== undefined){
                                                    return <div className="cancel-note-desc" key={key}>{`Last ${item[1]} hours before travel: ${item[2]}% deduction from fare`}</div>
                                                }
                                                if(-1==item[1] && item[2] !== undefined){
                                                    return <div className="cancel-note-desc" key={key}>{`Till ${item[0]} hours before travel: ${item[2]}% deduction from fare`}</div>
                                                }
                                                if(item[2] !== undefined) {
                                                    return <div className="cancel-note-desc" key={key}>{`${item[0]} hours before travel: ${item[2]}% deduction from fare`}</div>
                                                }
                                            })
	                                    }
									</div>
								</div>
							</div>
                        </Collapse>
                    </div>
                </div>
            </div>
        );
    }
}