import {connect} from 'react-redux';
import { Toaster, Intent, Position } from '@blueprintjs/core';
import RedeemCoin from '../components/RedeemCoin';

import {checkGGCoinBalance} from '../../core/actions/action';
import {coinAction} from '../../core/actions';

const mapStateToProps = state => {
    return{       
        coinRes:state.Booking.coinData,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        checkGGCoinBalance :(param) => {
            dispatch(coinAction([]));
            return checkGGCoinBalance(param)
                .then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                        dispatch(coinAction(sources.data.coin_balance));
                    }else{
                        dispatch(coinAction([]));
                        Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
                    }
                    return new Promise((resolve,reject)=>{
                        resolve(sources)
                    })
                }).catch((error)=>{
                    const sources = error.response;
                    Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});

                    return new Promise((resolve,reject)=>{
                        reject(sources)
                    })
                })
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RedeemCoin);