import React from 'react';
import {Dialog, Classes} from "@blueprintjs/core";
import GetTicketContainer from "./containers/GetTicketContainer";

export default class Ticket extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loadingData: true
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
       this.setState({ ticketModalIsOpen: nextProps.ticketModalIsOpen })
    }

    closeTicketModal = (props) => {
        this.setState({ ticketModalIsOpen: false })
    }

    render() {
        return (
            <React.Fragment>
                <Dialog isOpen={this.state.ticketModalIsOpen} onClose={this.closeTicketModal} title={ this.props.isBookingHistory ? "Booking Detail" : "Get Ticket"} className="bp3-dialog-large ticket-model">
                    <div className={Classes.DIALOG_BODY}>
                        <GetTicketContainer />
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>

                        </div>
                    </div>
                </Dialog>
            </React.Fragment>
        );
    }
}