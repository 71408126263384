import {connect} from 'react-redux';
import PassengerForm from '../components/PassengerForm';

import {initialBooking} from '../../core/actions/action';
// import {initialBookingAction} from '../../core/actions';


const mapStateToProps = state => {
    return{       
        //initialBookingRes:state.Booking.initialBookingRes,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        initialBooking :(param) => {
            return initialBooking(param);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PassengerForm);