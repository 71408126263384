import React from 'react';
import SearchBarContainer from "../home/container/SearchBarContainer";
import RecentSearch from "../home/components/RecentSearch";
import BookingHistoryContainer from "../home/container/BookingHistoryContainer";
import Ticket from "../ticket";

export default class Home extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loadingData: true,
            ticketModalIsOpen:false
        }
    }

    openTicketModal = (e) => {
        e.preventDefault();
        this.setState({ ticketModalIsOpen: true });
    }

    nextPage(path, parameter){
        this.props.history.push(path,{
          query:parameter
        });
      }

    render() {
        return (
            <React.Fragment>
                <div className="home-header container-fluid">
                    <div className="container pt-3 pb-5 pt-s-1 px-s-0">
                        <div className="home-get-ticket"><a href="void(0)" onClick={this.openTicketModal}>Get ticket</a></div>
                            <div className="home-banner-text">
                                <h1 className="text-main">Stop Looking. Start Travelling!</h1>
                                <p className="text-main">&nbsp;</p>
                            </div>
                        <SearchBarContainer 
                            nextPage={(path, parameter)=>{
                                this.nextPage(path, parameter)
                            }}
                        />
                    </div>
                </div>

                <div className="container">
                    <RecentSearch />
                    <BookingHistoryContainer history={this.props.history} />
                </div>
                <Ticket ticketModalIsOpen={this.state.ticketModalIsOpen} />
            </React.Fragment>
        );
    }
}
