import React, {Component} from 'react';

export default class Seat extends Component{

    constructor(props){
        
        super(props);
        
        this.state={
            selected: false,
            details:this.props.details
        };
    }

    handleClick = () => {
        
        if("false"===this.state.details.available){
            return;
        }

        if(true===this.state.selected){

            this.setState({
                selected:false
            });

            this.props.onSeatUnSelect(this.state.details);

        } else{

            this.setState({
                selected: true
            });
            this.props.onSeatSelect(this.state.details);
        }
    }

    render(){
        return(
            <div className={this.state.selected ? 'seat text-center user-selected-seat' : 'seat text-center'} onClick={this.handleClick}>
                <span style={{'fontSize':'10px','verticalAlign':'middle'}}>{this.state.details.name}</span>
            </div>
        );
    }
    
}