import {ssoInstance} from 'gg-react-utilities';
import Config from '../../Config';

export const getSourceCity = () => {
    return ssoInstance.get(Config.API_URL + 'sources');
}

export const getTicketDetail = (param) => {
    return ssoInstance.post(Config.API_URL + 'tickets', param);
}

export const getBookingHistory = (param) => {
    return ssoInstance.post(param.url, param);
}

export const getBookingDetail = (param) => {
    return ssoInstance.post(Config.API_URL + 'booking_detail', param);
}

export const getTripList = (param) => {
    return ssoInstance.get(Config.API_URL + 'trips?source_id='+param.source_id+'&destination_id='+param.destination_id+'&doj='+param.doj);
}

export const initialBooking = (param) => {
    return ssoInstance.post(Config.API_URL + 'initiate-bus-booking', param);
}

export const checkGGCoinBalance = () => {
    return ssoInstance.get(Config.API_URL + 'check-coin-balance');
}