import {connect} from 'react-redux';
import { Toaster, Intent, Position } from '@blueprintjs/core';
import Ticket from '../components/Ticket';

import {getTicketDetail} from '../../core/actions/action';
import {ticketDetailAction} from '../../core/actions';

const mapStateToProps = state => {    
    return{       
        ticketDetail:state.Booking.ticketDetail,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getTicketDetail :(param) => {
            dispatch(ticketDetailAction(null));
            return getTicketDetail(param)
                .then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                        dispatch(ticketDetailAction(sources.data));
                    }else{
                        dispatch(ticketDetailAction(null));
                        Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
                    }
                    return new Promise((resolve,reject)=>{
                        resolve(sources)
                    })
                }).catch((error)=>{
                    const sources = error.response;
                    dispatch(ticketDetailAction(null));
                    if (sources.data.message === "Validation Error") {
                        sources.data.error.map((res) => {
                            Toaster.create({position: Position.TOP}).show({message:res,intent: Intent.DANGER});
                            return res
                        })
                    } else {
                        Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
                    }
                    return new Promise((resolve,reject)=>{
                        reject(sources)
                    })
                })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Ticket);