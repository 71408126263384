import Seat from '../components/Seat';
import { connect } from 'react-redux';
import * as actions from '../../core/actions/index';

const mapStateToProps = state => {
    return {
        
    }
}

const mapDispatchToProps = dispatch => {

    return {
        onSeatUnSelect:(details)=>{
            dispatch(actions.unSelectSeat(details));
        },
        onSeatSelect:(details)=>{
            dispatch(actions.selectSeat(details));
        }
    }

}

const SeatContainer = connect(mapStateToProps, mapDispatchToProps)(Seat);

export default SeatContainer;